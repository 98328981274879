@import "../../../assets/scss/screen.scss";
@import "../../../assets/scss/colors.module.scss";

.render_address_wrapper{
    &:not(:first-child):last-child{
        .render_address_container {
            .render_address_edit_section_line{
                @include mobile {
                    &.isCheck{
                        border-bottom: none;
                    }
                }
            }
        }
    }
    &:last-child{
        .edit_address_form_line{
            @include mobile {
                border-bottom: none;
            }
        }
    }
    .render_address_container {
        width: 50%;
        display: flex;
        flex-direction: row;
        padding-top: 32px;
        border-top: none;
        &.isCheck {
            border-top: 1px solid $grey-6;
            @include mobile {
                border-top: none;
            }
        }
        @include tablet {
            width: 100%;
        }
        @include mobile {
            flex-direction: column;
            padding-top: 16px;
            border-top: none;
        }
        .render_address_details_holder {
            display: flex;
            flex-direction: column;
            width: 75%;
            color: $grey-1;
            @include mobile {
                width: 100%;
            }
            .render_address_label {
                color: $grey-1;
            }
            .render_address_default_field {
                padding: 16px 0 0;
                display: flex;
                &.isVisible{
                    padding: 24px 0 16px;
                    @include mobile {
                        padding: 8px 0 19px;
                    }
                }
                label {
                    color: $grey-1;
                    padding-left: 28px;
                }
                label.errl:after{
                    background-color: $dark-blue-4;
                }
                input.erri:checked ~ label.errl:before {
                    border: 2px solid $dark-blue-4 !important;
                }
                label.errl:before {
                    border: 2px solid $grey-3 !important;
                }
                input{
                    &:focus ~ {
                        label:before {
                            box-shadow: none !important;
                        }
                    }
                }
                @include mobile {
                    width: 100%;
                    padding: 8px 0 19px;
                }
            }
        }
        .render_address_edit_section_line {
            @include mobile{
                border-bottom: none;
                max-width: 150px;
                &.isCheck {
                    border-bottom: 1px solid $grey-6;
                }
            }
            .render_address_edit_section {
                display: flex;
                justify-content: space-between;
                padding-bottom: 30px;
                height: 19px;
                width: 130px;
                .render_address_edit_delete_button {
                    color: $orange-1;
                    cursor: pointer;
                }
            }
        }
    }
    .render_address_edit_account_section {
        padding-top: 30px;
        width: 65%;
        @include tablet {
            width: 100%;
        }
    } 
}
