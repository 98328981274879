@import "../../../assets/scss/colors.module.scss";
@import "../../../assets//scss/screen.scss";

.support-not-found-container {
  text-align: center;
  margin-top: 50px;
  @include mobile {
    margin: 0 14px;
  }
  .error-circle {
    width: 246px;
    height: 246px;
    margin: 0 auto;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: 2px solid $grey-6; 
    @include mobile {
      margin-top: 33px;
    }
    .error-image {
        width: 60px;
        height: 60px;
      }
  }
  .sorry-text {
    margin: 50px 0 15px;
    color: $grey-1;
  }
  .body-text {
    margin-bottom: 51px;
    color: $dim-grey;
    @include mobile {
      margin-bottom: 39px;
    }
  }
  .help-line-holder {
    display: flex;
    flex-direction: column;
    @include mobile {
      flex-direction: column;
    }
    .help-line {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        justify-content: center;
        color: $grey-1;
        &.padd-right {
            padding: 0 30px 0 0;
        }
        .help-line-text {
          margin: 0;
        }
        .email-icon {
            width: 22px;
            height: 15px;
            padding: 0 18px 0 0;
        }
        .phone-icon {
            width: 22px;
            height: 24px;
            padding: 0 22px 0 0;
        }
    }
  }
}