@import "../../../assets/scss/screen.scss";
@import "../../../assets/scss/colors.module.scss";

.edit_credit_card_container {
    label.primary-input-label {
        box-sizing: border-box;
        &.error_border {
            margin-left: 0px;
            margin-right: 0px;
        }
    }
    .edit_credit_card_line {
        @include mobile {
            width: 150px;
            border-bottom: 1px solid $grey-6;
        }
    }
}