@import '../../../../assets/scss/screen.scss';
@import "../../../../assets/scss/colors.module.scss";

.container {
    display: flex;
    align-items: center;
    padding: 50px 0px;
    &.dashboard_support {
        padding: unset;
    }
    .subContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        @include mobile {
            display: block;
        }
        .setImage_visible {
            margin-right: 25px;
            margin-top: 4px;
            @include mobile {
                margin-bottom: 21px;
            }
        .InnerImage {
            width: 63px;
            height: 63px;
            background: $orange-1;
            padding: 12px;
            border-radius: 12px;
            @include mobile {
                width: 40px;
                height: 40px;
            }
        }
    }
    .setImage_hidden {
        display: none;
        visibility: hidden;
    }
    .heading_container {
        width: 41rem;
        &.dashboard_support {
            width: unset;
            &.latest_support_active {
                padding-bottom: 15px;
            }
        }
        @include tablet {
            width: 90%;
        }
        @include mobile {
            width: 100%;
        }
        .heading {
            // font-size: 17px;
            // line-height: 148%;
            color: $grey-1;
        }
        .subHeading {
            // font-weight: 400;
            // font-size: 17px;
            // line-height: 148%;
            color: $grey-2;
            margin-top: 12px;
        }
    }
  }
}


.style_border_bottom {
    border-bottom: 1px solid $grey-6;
}
.style_border_top {
    border-top: 1px solid $grey-6;
}