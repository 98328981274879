@import '../../../assets/scss/colors.module.scss';
.loader-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
}

.cover-screen-grey{
    z-index: 1200 !important;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $grey-5;
}