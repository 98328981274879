@import "../../assets/scss/screen.scss";
@import "../../assets/scss/colors.module.scss";

.checkout_header_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    @include tablet {
        flex-direction: column;
    }
    .header {
        display: flex;
        align-items: center;
        height: 80px;
        width: 100%;
        background: $white;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
        flex-direction: row;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid $bright-grey2;
        @include tablet {
          width: auto;
        }
    }
    .logo_container {
        display: flex;
        align-items: center;
        width: 158px;
        height: 100%;
        a {
            background-image: url(https://assets.eargo.com/pdp/new_images/eargo_logo.png);
            &.jp2 {
                background-image: url(https://assets.eargo.com/pdp/new_images/jp2/eargo_logo.jp2);
            }
            &.webp {
                background-image: url(https://assets.eargo.com/pdp/new_images/webp/eargo_logo.webp);
            }
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 100%;
            height: 80%;
        }
    }
    .photo_container {
        display: flex;
        justify-content: center;
        align-content: center;
        flex: 0 0 auto;
        a {
        display: flex;
        align-self: center;
        font-size: 18px;
        @include tablet {
            color: transparent;
            background: url(https://assets.eargo.com/header/telephone.svg);
            width: 30px;
            height: 30px;
            overflow: hidden;
            display: flex;
        }
        &:before {
                content:url('https://assets.eargo.com/account/phone.svg');
                position:relative;
                left: -5px;
                top: 2px;
                @include tablet {
                    display: none;
                }
            }
        }
    }
}