
@import "../../../assets/scss/screen.scss";
@import "../../../assets/scss/colors.module.scss";

.edit_account_form {
    display: block;
    .redux-primary-input-holder {
        margin-bottom: 10px;
        input.primary-input-field:disabled {
            color: $grey-3;
            cursor: not-allowed;
            background-color: $white;
        }
        label.primary-input-label {
            box-sizing: border-box;
            &.error_border {
                margin: 0px;
            }
        }
    }
    .edit_account_one_line_section {
        display: flex;
        border-radius: 3px;
        div {
            width: 50%;
            &:first-child {
                margin-right: 10px;
            }
            @include mobile {
                width: 100%;
            }
        }
        @include mobile {
            flex-direction: column;
        }
    }
    .edit_account_button_holder {
        display: inline-flex;
        padding: 35px 0;
        .edit_account_cancel_button {
            // font-size: 18px;
            // line-height: 26px;
            color: $orange-1;
            display: flex;
            cursor: pointer;
            padding: 8px 26px;
            align-items: center;
        }
    }
    .edit_account_error_message {
        color: $error-red;
        // font-size: 14px;
        // font-weight: 400;
    }
}