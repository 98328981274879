@import "../../../assets/scss/colors.module.scss";
@import "../../../assets/scss/screen.scss";

.add_new_address_container {
    display: flex;
    flex-direction: column;
    .heading {
        // font-size: 24px;
        // line-height: 36px;
        color: $grey-1;
        margin: 0px 0px 34px;
    }
    .address_form {
        display: flex;
        flex-direction: column;    
        .redux-primary-input-holder {
            margin-bottom: 24px;
            .primary-input-label.error_border {
                margin: 0px;
            }
        }
        .one_line {
            display: flex;
            border-radius: 3px;
            div {
                width: 50%;
            }
            @include mobile {
                display: initial;
                div {
                    width: 100%;
                }
            }
            div {
                &:last-child {
                    margin-left: 10px;
                    @include mobile {
                        margin-left: 0px;
                    }
                }
            }       
        }
        .two_line_section {
            display: flex;
            @include mobile {
              flex-direction: column;
            }
            .ip_holder_2 {
                cursor: pointer;
                display: flex;
                position: relative;
                border: 1px solid $grey-6;
                border-radius: 3px;
                outline: 0px;
                width: 50%;
                margin-right: 10px;
                height: 75px;
                @include mobile {
                    width: 100%;
                    margin: 0px;
                    height: 60px;
                    margin-bottom: 10px !important;
                }
                // &.state_error_border {
                //     font-size: 14px;
                // }
                &.error_border {
                    display: flex;
                    z-index: 1;
                    border: 1px solid $error-red !important;
                    border-right: 1px solid $error-red;
                    h1 {
                      color: $error-red !important;
                    }
                  }
                .state_label {
                    padding-left: 16px !important;
                    position: absolute;
                    z-index: 1;
                    color: $grey-2;
                    transform-origin: 0px 0px 0px;
                    transition: transform 0.3s ease-out 0s;
                    &.floating_label {
                        top: 0;
                        transform: translateY(-1.5rem) scale(0.9);
                        padding: 2.25rem 0px 0px 0px;
                    }
                    &.label {
                        top: 0px;
                        bottom: 0px;
                        display: flex;
                        align-items: center;
                    }
                    @include mobile {
                      padding-left: 16px !important;
                    }
                }
                .state_span {
                    position: absolute;
                    top: 35px;
                    z-index: 1;
                    color: $grey-1;
                    padding-left: 16px;
                    font-size: 16px;
                    font-family: 'Inter', sans-serif;
                    font-weight: 400;
                    margin-left: 2px;
                    margin-right: 2px;
                    @include mobile {
                        top: 28px;
                        padding-left: 16px;
                    }
                }
            }
            .zip_field {
                width: 50%;
                @include mobile {
                  width: 100%;
                }
            }
        }
        .checkbox_holder {
            display: flex;
            padding-top: 17px;
            @include mobile {
                padding-top: 24px;
            }
            .estpch{
                color:$grey-2;
            }
        }
        .error_message{
            color: $error-red;
            text-align: left;
            // font-size: 14px;
            margin-top: 12px;
        }
    }
}