@import './screen.scss';
@import url('https://rsms.me/inter/inter.css');
h1,
h2,
h3,
h4,
h5,
h6,
h7,
th,
td,
.body,
.body_bold,
.err_regular,
.caption,
.err_bold,
input,
.input_field_text,
.body_light,
.append_icon {
  &.typographyV2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
  }
}
input {
  &.typographyV2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
  }
}
.append_icon {
  &.typographyV2 {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
  }
}
h1 {
  &.typographyV2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 110%;
    @include mobile {
      font-size: 40px;
      line-height: 124%;
    }
    &.super {
      font-weight: 900;
      font-size: 112px;
      line-height: 102.5%;
      @include tablet {
        font-weight: 900;
        font-size: 80px;
      }
      @include mobile {
        font-size: 56px;
        line-height: 112.5%;
      }
    }
    &.floating_label {
      font-weight: 300;
      font-size: 13px;
      line-height: 14px;
    }
    &.label {
      font-weight: 300;
      font-size: 16px;
      line-height: 18px;
    }
  }
}

h2 {
  &.typographyV2 {
    font-weight: bold;
    font-size: 48px;
    line-height: 116%;
    @include mobile {
      font-size: 36px;
      line-height: 126%;
    }
    &.super {
      font-weight: bold;
      font-size: 80px;
      line-height: 116%;
      @include mobile {
        font-size: 48px;
      }
    }
  }
}
h3 {
  &.typographyV2 {
    font-weight: normal;
    font-size: 40px;
    line-height: 124%;
    @include mobile {
      font-size: 32px;
      line-height: 128%;
    }
    &.super {
      font-weight: bold;
      font-size: 60px;
      line-height: 124%;
      @include mobile {
        font-size: 40px;
      }
    }
  }
}
h4 {
  &.typographyV2 {
    font-weight: 300;
    font-size: 32px;
    line-height: 128%;
    @include mobile {
      font-size: 24px;
      line-height: 132%;
    }
    &.super {
      font-weight: bold;
      font-size: 48px;
      line-height: 132%;
      @include mobile {
        font-size: 32px;
        line-height: 128%;
      }
    }
  }
}
h5 {
  &.typographyV2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 132%;
    @include tablet {
      font-weight: bold;
      font-size: 24px;
    }
    @include mobile {
      font-size: 20px;
      line-height: 136%;
    }
  }
}
h6 {
  &.typographyV2 {
    font-weight: normal;
    font-size: 24px;
    line-height: 132%;
    @include mobile {
      font-size: 20px;
      line-height: 136%;
    }
  }
}
h7,
th,
td {
  &.typographyV2 {
    font-weight: bold;
    font-size: 18px;
    line-height: 148%;
    @include mobile {
      font-size: 16px;
      line-height: 152%;
    }
  }
}
.body {
  &.typographyV2 {
    font-weight: normal;
    font-size: 18px;
    line-height: 148%;
    @include mobile {
      font-size: 16px;
      line-height: 152%;
    }
  }
}
.body_bold {
  &.typographyV2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 148%;
    @include mobile {
      font-size: 16px;
      line-height: 152%;
    }
  }
}
.body_small {
  &.typographyV2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 148%;
    @include mobile {
      font-size: 14px;
      line-height: 152%;
    }
  }
}
.err_regular {
  &.typographyV2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.err_bold {
  &.typographyV2 {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
}
.input_field_text {
  &.typographyV2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
  }
}

.body_light {
  &.typographyV2 {
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
  }
}

.caption {
  &.typographyV2 {
    font-weight: normal;
    font-size: 12px;
    line-height: 176%;
  }
}

.caption_bold {
  &.typographyV2 {
    font-weight: 700;
    font-size: 12px;
    line-height: 176%;
  }
}

// v2 styles

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div,
label,
ul,
li,
ol {
  &.typeV2 {
    &-h1-super {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 900;
      font-size: 112px;
      line-height: 115px;

      @include mobile {
        font-size: 56px;
        line-height: 63px;
      }
    }

    &-h2-super {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 80px;
      line-height: 93px;

      @include mobile {
        font-size: 48px;
        line-height: 56px;
      }
    }

    &-h3-super {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 74px;

      @include mobile {
        font-size: 40px;
        line-height: 50px;
      }
    }

    &-h4-super {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 63px;

      @include mobile {
        font-size: 32px;
        line-height: 41px;
      }
    }

    &-h1 {
      font-family: Inter, sans-serif;
      text-transform: uppercase;
      font-style: normal;
      font-weight: 900;
      font-size: 56px;
      line-height: 62px;

      @include mobile {
        font-size: 40px;
        line-height: 50px;
      }
    }

    &-h2 {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 56px;

      @include mobile {
        font-size: 36px;
        line-height: 45px;
      }
    }

    &-h3 {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 50px;

      @include mobile {
        font-size: 32px;
        line-height: 41px;
      }
    }

    &-h4 {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 32px;
      line-height: 41px;

      @include mobile {
        font-size: 24px;
        line-height: 32px;
      }
    }

    &-h5 {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;

      @include mobile {
        font-size: 20px;
        line-height: 27px;
      }
    }

    &-h6 {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;

      @include mobile {
        font-size: 20px;
        line-height: 27px;
      }
    }

    &-h7 {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;

      @include mobile {
        font-size: 20px;
        line-height: 27px;
      }
    }

    &-body {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;

      @include mobile {
        font-size: 16px;
        line-height: 24px;
      }

      &-bold {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;

        @include mobile {
          font-size: 16px;
          line-height: 24px;
        }
      }

      &-small {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;

        @include mobile {
          font-size: 14px;
          line-height: 21px;
        }
      }

      &-caption {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;

        @include mobile {
          font-size: 12px;
          line-height: 21px;
        }
      }
    }
  }
}
