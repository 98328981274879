@import '../../assets//scss/screen.scss';

.wrapper {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  margin: 25px auto;
  border-radius: 50%;
  border: 1px solid #D9D9D9;
  @include mobile{
    width: 70px;
    height: 70px;
  }
  .leftHalf, .rightHalf, .spinner {
    top: 0;
    position: absolute;
    width: 50%;
    height: 100%;
  }
  
  .leftHalf {
    left: 0;
    background: #fff;
    z-index: 3;
    opacity: 1;
    animation: showHide 2s infinite steps(1, end);
    border-radius: 100% 0 0 100%/ 50% 0 0 50%;
  }
  
  .rightHalf {
    right: 0;
    background: #2C87EF;
    z-index: 1;
    opacity: 0;
    animation: showHide 2s infinite steps(1, end) reverse;
    border-radius: 0 100% 100% 0/ 0 50% 50% 0;
  }
  
  .spinner {
    overflow: hidden;
    left: 0;
    background: #2C87EF;
    animation: spin 2s linear infinite;
    transform-origin: center right;
    z-index: 2;
    border-radius: 100% 0 0 100%/ 50% 0 0 50%;
  }
  
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes showHide {
    0% {
      opacity: 1;
    }
    
    50%, 100% {
      opacity: 0;
    }
  }
}
