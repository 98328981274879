@import "../../../assets/scss/screen.scss";
@import "../../../assets/scss/colors.module.scss";

.address_section_container {
    background-color: $white;
    border-radius: 20px;
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
    padding: 35px;
    margin-bottom: 15px;
    padding-bottom: 0px;
    @include mobile {
        padding: 35px 15px;
    }
    .address_section_header_container {
        display: flex;
        border-bottom: 1px solid $grey-6;
        .address_section_show_section {
            // font-size: 12px;
            // opacity: ${props => !props.isActive ? 1 : 0.5};
            opacity: 1;
            color: $dark-blue-4;
            margin-top: 10px;
            margin-left: 50px;
            @include mobile {
                margin-left: 20px;
            }
            ::after {
                cursor: pointer;
                margin-left: 10px;
                @include mobile {
                    margin-left: 5px;
                }
            }
            &.isActive {
                opacity: 0.5;
            }
            .address_section_show_label {
                align-items: center;
                display: flex;
                // font-size: 14px;
                // cursor: ${props => !props.isActive ? 'pointer' : 'not-allowed'};
                cursor: 'pointer';
                &.isActive{
                    cursor: 'not-allowed';
                }
            }
        }
    }
    .address_details_section_container {
        transition: all 0.3s ease;
        .address_details_no_info {
            font-size : 18px;
            padding-top: 26px;
            color: $grey-1;
            @include mobile {
                padding-bottom: 24px;
            }
        }
        .address_details_warning_message {
            margin-bottom:-12px;
            color: $error-red;
            // font-size: 14px;
            // font-weight: 400;
            @include mobile {
                width: 100%;
                margin-bottom: 12px;
            }
        }
        .address_details_add_button_section {
            padding: 24px 0 56px 0;
            @include mobile {
                padding: 0px 0px 12px;
            }
        }
    }
}