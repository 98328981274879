@import "../../../assets/scss/screen.scss";
@import "../../../assets/scss/colors.module.scss";

.payment_information_wrapper{
    &:not(:first-child):last-child{
        .payment_information_container {
            .payment_information_details_container{
                .payment_information_edit_section_line{
                    @include mobile{
                        &.isCheck {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
    &:last-child{
        .edit_credit_card_line{
            @include mobile{
                border-bottom: none;
            }
        }
    }
    .payment_information_container{
        display: flex;
        flex-direction: column;
        padding-top: 32px; 
        &.isCheck {
            border-top: 1px solid $grey-6;
            @include mobile {
                border-top: none;
            }
        }
        .payment_information_card_expired_message{
            color: $error-red;
            padding: 0 0 15px;
        }
        .payment_information_details_container {
            width: 50%;
            display: flex;
            flex-direction: row;
            border-top: none;
            @include tablet {
                width: 100%;
            }
            @include mobile {
                flex-direction: column;
                padding-top: 16px;
                border-top: none;
            }
            .payment_information_holder_details {
                display: flex;
                flex-direction: column;
                width: 75%;
                color: $grey-1;
                @include mobile {
                    width: 100%;
                }
                &.expiredCard{
                    opacity: 0.5;
                    cursor: not-allowed;
                    label{
                        cursor: not-allowed;
                    }
                }
                .payment_information_account_label {
                    color: $grey-1;
                }
                .payment_information_card_info {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    padding-top: 4px;
                    .payment_information_card_logo {
                        width: 30px;
                        margin-right: 10px;
                    }
                    .payment_information_card_number{
                        color: $grey-1;
                    }
                    .payment_information_expiry_date {
                        padding-left: 16px;
                        color: $grey-1;
                    }
                }
                .payment_information_default_field {
                    padding: 24px 0 0;
                    display: flex;
                    label {
                        color: $grey-1;
                        padding-left: 28px;
                    }
                    label.errl:after{
                        background-color: $dark-blue-4;
                    }
                    input.erri:checked ~ label.errl:before {
                        border: 2px solid $dark-blue-4 !important;
                    }
                    label.errl:before {
                        border: 2px solid $grey-3 !important;
                    }
                    input{
                        &:focus ~ {
                            label:before {
                                box-shadow: none !important;
                            }
                        }
                    }
                    @include mobile {
                        padding: 8px 0 19px;
                    }
                    &.isVisible{
                        padding: 12px 0;
                        @include mobile {
                            padding: 8px 0 19px;
                        }
                    }
                }
            }
            .payment_information_edit_section_line {
                @include mobile{
                    border-bottom: none;
                    max-width: 150px;
                    &.isCheck {
                        border-bottom: 1px solid $grey-6;
                    }
                }
                .payment_information_edit_section {
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 12px;
                    height: 19px;
                    width: 130px;
                @include mobile {
                    padding-bottom: 30px;
                }
                .payment_information_edit_delete_button {
                    color: $orange-1;
                    cursor: pointer;
                }
            }
            }
        }
    }
    .edit_payment_information_section {
        padding-top: 30px;
        width: 60%;
        @include tablet {
            width: 100%;
        }
    }
}
