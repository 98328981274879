@import "../../assets/scss/colors.module.scss";
@import "../../assets/scss/screen.scss";

.card_form {
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  .redux-primary-input-holder {
    margin-bottom: 24px;
    label.primary-input-label {
      box-sizing: border-box;
      &.error_border {
        margin: 0px;
      }
    }
    label.primary-input-label-disabled {
      background: rgb(236, 236, 236);
      input:disabled {
        box-shadow: none;
        background: rgb(236, 236, 236);
      }
    }
  }
  .common-state-zipcode-fields {
    @include mobile {
      margin-bottom: 0px;
    }
  }
  .card_form_container {
    card-name-container {
      margin-bottom: 20px;
      ::placeholder {
        color: $grey-2;
        opacity: 1;
      }
    }
    .padding-left-0 {
      padding-left: 0px;
    }
    .padding-left-24 {
      padding-left: 24px;
    }
    .card-containers {
      height: 75px;
      padding-left: 24px;
      margin-bottom: 10px;
      border: $grey-6 1px solid;
      border-radius: 3px;
      display: flex;
      position: relative;
      @include mobile {
        height: 60px;
      }
      h1.label {
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        color: $grey-2;
        transform-origin: 0px 0px 0px;
        transition: transform 0.3s ease-out 0s;
      }
      h1.floating_label {
        position: absolute;
        top: 0;
        color: $grey-2;
        transform-origin: 0px 0px 0px;
        transform: translateY(-1.5rem) scale(0.9);
        padding-top: 2.25rem;
        transition: transform 0.3s ease-out 0s;
      }
      &.error_border {
        h1.label, h1.floating_label {
          color: $error-red;
        }
      }
      &.primary-input-label {
        h1.label {
          padding-left: 0px;
        }
        // &.error_border {
        //   h1.label, h1.floating_label {
        //     font-size: 14px;
        //   }
        // }
        // &.redux-number-input {
        //   h1.label, h1.floating_label {
        //     font-size: 18px;
        //   }
        // }
      }
    }
    .card-inputs {
      width: 100%;
      padding: 35px 0 0 24px;
      @include mobile {
        padding: 30px 16px 24px 16px;
      }
    }
    .cyber-source-card-inputs,
    .cyber-source-card-cvc-input {
      width: 100%;
      padding: 25px 0 0 0px;
      overflow-y: hidden;
      @include mobile {
        padding: 16px 0 0 0;
        z-index: 50;
      }
    }
    .flex-container {
      display: flex;
      &.for-cbs {
        @include mobile {
          display: unset;
        }
      }
      .card-exp-container  {
        @include mobile {
          margin-bottom: 24px;
        }            
      }
      @include mobile {
        flex-direction: column;
      }
      label {
        width: 50%;
        height: 75px;
        overflow: auto;
        &:last-child {
          margin-left: 10px;
          @include mobile {
            margin-left: 0px;
          }
        }
        @include mobile {
          width: 99%;
          height: 60px;
        }
      }
      &.for-cbs {
        label {
          @include mobile {
            width: auto;
          }
        }
      }
    }
    .name-input {
      width: 100%;
      padding-left: 24px;
    }
    .cardholder-name {
      span {
        padding-left: 24px;
        @include mobile {
          padding-left: 16px;
        }
      }
    }
    .card-exp-container, .card-cvc-container {
      margin-bottom: 0px;
      border-radius: 0px;
    }
    .card-exp-container {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      @include mobile {
        border-radius: 3px;
      }
    }
    .card-cvc-container {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      @include mobile {
        border-radius: 3px;
      }
    }
    .address_box_container {
      cursor: pointer;
      display: flex;
      position: relative;
      border: 1px solid $grey-6;
      border-radius: 3px;
      outline: 0px;
      height: 75px;
      margin-bottom: 10px;
      @include mobile {
        margin: 24px 0px;
        height: 60px;
      }
      &.error {
        align-items: center;
        height: 67px;
        border: 1px solid $error-red;
        background: $error-red;
        color: $white;
      }
      @include mobile {
        height: 60px;
      }
      // &.state_error_border {
      //   font-size: 14px;
      // }
      .select_box_label {
        padding-left: 24px !important;
        position: absolute;
        z-index: 1;
        color: $grey-2;
        transform-origin: 0px 0px 0px;
        transition: transform 0.3s ease-out 0s;
        &.floating_label {
          top: 0;
          transform: translateY(-1.5rem) scale(0.9);
          padding: 2.25rem 0px 0px 0px;
        }
        &.label {
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
        }
        @include mobile {
          padding-left: 16px !important;
        }
      }
      .state_span {
        position: absolute;
        top: 35px;
        padding-left: 24px;
        color: $grey-1;
        @include mobile {
          padding-left: 16px;
        }
      }
    }
  }
  .check_box_holder {
    display: flex;
    padding-top: 22px;
    .estpch{
      color:$grey-2;
    }
  }
  .error_message {
    margin-top: 12px;
    color: $error-red;
    // font-size: 14px;
    // font-weight: 400;
  }
  .button_holder {
    display: flex;
    padding-top: 21px;
    justify-content: center;
    // .save_button {
    //   width: 280px;
    //   :disabled {
    //     cursor: not-allowed;
    //     opacity: 0.6;
    //   }
    //   @include mobile {
    //     width: 200px;
    //   }
    // }
  }
}