@import "../../../assets/scss/screen.scss";
@import "../../../assets/scss/colors.module.scss";

.account_section_container {
    background-Color: $white;
    border-radius: 20px;
    padding: 35px;
    margin-bottom: 15px;
    padding-bottom: 0px;
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
    @include mobile {
      padding: 35px 15px;
    }
    .account_section_sub_container {
        overflow: hidden;
        transition: all 0.3s ease;
        .account_details {
            width: 50%;
            display: flex;
            flex-direction: row;
            padding: 32px 0 52px;
            @include tablet {
                width: 100%;
            }
            @include mobile {
                flex-direction: column;
                padding: 8px 0 0;
            }
            .account_holder_details {
                display: flex;
                flex-direction: column;
                width: 75%;
                @include mobile {
                    width: 100%;
                }
                .account_label {
                    padding: 4px 0;
                    // font-size: 18px;
                    // line-height: 26px;
                    color: $grey-1;
                    @include mobile {
                        padding: 8px 0;
                    }
                }
            }
            .account_edit_section {
                display: flex;
                justify-content: space-between;
                padding-bottom: 12px;
                height: 19px;
                width: 130px;
                @include mobile {
                    padding: 8px 0 30px 0px;
                }
                .account_edit_button {
                    // font-size: 18px;
                    color: $orange-1;
                    cursor: pointer;
                    padding-top: 4px;
                }
            }
        }
        .account_no_info {
            // font-size : 18px;
            padding-top: 26px;
            padding-bottom: 60px;
            color: $grey-1; 
        }
        .account_edit {
            padding-top: 25px;
            width: 65%;
            @include tablet {
                width: 100%;
            }
        }
    }
}