@import "../../../../assets/scss/colors.module.scss";

.long-invoice-form{
    cursor: pointer;
    img{
        width: 14px;
        height: 16px;
        margin: auto;
    }
    .long-form-invoice {
        margin: unset;
        margin-left: 5px;
        color: $dark-blue-3;
    }
}
