@import "../../assets/scss/colors.module.scss";
@import "../../assets/scss/screen.scss";

.two_line_section_address {
    display: flex;
    justify-content: row;
    height: 75px;
    margin-bottom: 10px;
    box-sizing: border-box;
    @include mobile {
        width: 100%;
        flex-direction: column;
        height: unset;
    }
    .state_select_box_container{
        width: 50%;
        cursor: pointer;
        display: flex;
        position: relative;
        border: 1px solid $grey-6;
        border-radius: 3px;
        outline: 0px;
        margin-right: 10px;
        height: 75px;
        @include mobile {
          height: 60px;
          width: 100%;
          margin: 0px;
          margin-bottom: 10px !important;
        }
        // &.state_error_border {
        //   font-size: 14px;
        // }
        &.state_error_border {
          h1.label, h1.floating_label {
            color: $error-red;
          }
        }
        .state_label {
            padding-left: 16px !important;
            position: absolute;
            z-index: 1;
            color: $grey-2;
            transform-origin: 0px 0px 0px;
            transition: transform 0.3s ease-out 0s;
            &.floating_label {
                top: 0;
                transform: translateY(-1.5rem) scale(0.9);
                padding: 2.25rem 0px 0px 0px;
            }
            &.label {
                top: 0px;
                bottom: 0px;
                display: flex;
                align-items: center;
            }
            @include mobile {
              padding-left: 16px !important;
            }
        }
        .state_span {
            position: absolute;
            top: 35px;
            z-index: 1;
            color: $grey-1;
            padding-left: 16px;
            margin-left: 2px;
            margin-right: 2px;
            @include mobile {
              top: 28px;
              padding-left: 16px;
            }
        }
    }
    .zip_field {
        width: 50%;
        @include mobile {
          width: 100%;
        }
    }
}