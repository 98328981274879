$white: #ffffff;
$black: #000000;
$black1: #262525;
$red: red;
$dim-grey: #666666;
$dim-grey2: #f0f0f0;
$dim-grey3: #e6e5ea;
$dim-grey4: #d8d6cf;
$dim-grey5: #f8f8f8;
$dim-grey6: #f6f6f6;
$dim-grey7: #F1F2F2;
$dim-grey8: #d6d6d6;
$dim-grey9: #d9d6cf;
$dim-grey10: #D8DFEB;
$dim-grey11: #E5E5E5;
$white-smoke: #f4f4f4;
$orange-1: #ff5e2b;
$orange-2: #ff7c00;
$orange-3: #f45a36;
$orange-4: #ff5e2b78;
$orange-5: #f17d50;
$orange-6: #ff764a;
$blue-1: #003D7D;
$blue-2: #3498db;
$dark-blue-1: #003c7d;
$dark-blue-2: #003B7D;
$dark-blue-3: #003C7D;
$dark-blue-4: #2C87EF;
$light-blue: #2684ff;
$light-blue1: #4c9aff;
$aqua-squeeze: #d9dbd9;
$green-1: #43A047;
$green-2: #3c763d;
$green-3: #2ecc71;
$grey-1: #333333;
$grey-2: #666666;
$grey-3: #999999;
$grey-4: #dfe0e2;
$grey-5: #f5f6f7;
$grey-6: #d9d9d9;
$grey-7: #848484;
$grey-8: #686868;
$grey-9: #F7F7F7;
$grey-10: #7995bf4d;
$grey-11: #F5F5F3;
$grey-12: #F5F5F3;
$grey-14: #d0d0d0;
$grey-15: #cccccc;
$grey-16: #c1c1ba;
$grey-17: #e0e0e0;
$grey-18: #4f4f51;
$grey-19: #818181;
$grey-20: #565656;
$grey-21: #5D5D5D;
$grey-22: #474648;
$grey-23: #b8b5b1;
$grey-24: #4a4a4a;
$grey-25: #9b9b9b;
$grey-26: #A1A1A1;
$grey-27: #aaaaaa;
$grey-28: #D9D9D9;
$grey-29: #7D7D7D;
$dusty-grey: #979797;
$dusty-grey2: #989898;
$bright_grey: #ececec;
$bright_grey1: #EFEFEF;
$bright-grey2: #f4fafc;
$error-red: #f16b6b;
$ghost-white: #F7F7FD;
$warning: #F2BF45;
$info: #3C7AB4;
