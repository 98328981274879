@import "../../assets//scss/colors.module.scss";
@import "../../assets/scss/screen.scss";

.activate_account_container {
    margin: 0 auto;
    flex-direction: column;
    background: $white;
    .form_container{
        max-width: 483px;
        width: 100%;
        display: flex;
        flex-direction: column;
        // .ecec {
        //     margin: ${props => props.otherError ? '20px 0;' : '0px'}
        // }
        // .button_container_in_verify_section{
        //     display: flex;
        //     justify-content: start;
        //     align-items: center;
        // }
        .back_to_login_tag {
            text-align: center;
            color: $dark-blue-4;
            cursor: pointer;
            margin: 40px 0px;
        }
        // .primary-input-label  {
        //     width: 330px;
        //     height: 56px;
        //     margin: ${props => props.otherError ? '7px auto 24px auto' : '7px auto 36px auto'};
        //     span {
        //         font-size: 18px;
        //         line-height: 26px;
        //         color: ${DIM_GREY};
        //         .label {
        //             color: ${ERROR_RED};
        //             padding: 15px 0;
        //         }
        //     }
        //     @media ${mobile} {
        //         width: calc(100% - 84px);
        //     }
        // }
        .message_container{
            max-width: 330px;
            margin: -12px 0px 12px;
            @include mobile {
                width: calc(100% - 64px);
            }
        }
    }
}