@import "../../assets//scss/colors.module.scss";
@import "../../assets/scss/screen.scss";

.forgot_password_main_container {
   width: 31%;
   @include mobile{
      width: 80%;
      margin-top: 30px;
   }
   .form_container{
      max-width: 483px;
      width: 100%;
      display: flex;
      flex-direction: column;
      // .error_border span {
      //     font-size: 14px;
      // }
      .input_field .primary-input-label {
         margin:0px 0px 32px;
         height: 55px;
      }
      .message_container{
         max-width: 330px;
         margin: -12px 0px 12px;
      }
      .send_button{
         margin-bottom: 40px;
         display: flex;
         justify-content: flex-start;
         align-items: center;
      }
   }
   .back_to_login_page {
      cursor: pointer;
      display: flex;
      justify-content: center;
      margin: 40px 0;
      color: $dark-blue-4;
   }
}

.activation_code_container {
   margin: 0 auto;
   flex-direction: column;
   background: $white;
   .form_container{
      max-width: 483px;
      width: 100%;
      display: flex;
      flex-direction: column;
   //    .ecec {
   //       margin: ${props => props.otherError ? '20px 0;' : '0px'}
   //   }
      .back_to_login_tag {
         text-align: center;
         color: $dark-blue-4;
         cursor: pointer;
         margin: 40px 0px;
      }
      .message_container{
         max-width: 330px;
         margin: -12px 0px 12px;
         @include mobile{
            width: calc(100% - 64px);
         }
      }
   }

   // .primary-input-label  {
   //     width: 330px;
   //     height: 56px;
   //     margin: 7px auto 12px auto;
   //     span {
   //         font-size: 14px;
   //         .label {
   //             padding: 15px 0;
   //         }
   //     }
   //     @media ${mobile} {
   //         width: calc(100% - 84px);
   //     }
   // }
}