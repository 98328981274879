@import "../../assets/scss/colors.module.scss";
@import "../../assets/scss/screen.scss";

.all_ship_address_container {
    padding-bottom: 21px;
    margin-top: 29px;
    border-bottom: 1px solid $grey-6;
    span {
        color: $grey-2;
    }
    input.erri {
        &:checked ~ {
            label.errl {
                &:before {
                    border: 2px solid $dark-blue-1;
                }
            }
        }
    }
    label.errl {
        padding-left: 30px;
        bottom: 15px;
        &:after {
          background: $dark-blue-1;
        }
    }
    .shipping_details_span{
        // font-size: 18px;
        // line-height: 28px;
        // color: $grey-1;
        display: flex;
        align-items: center;
        .estpch{
            color:$grey-2;
        }
    }
}
.shipping_footer{
    img {
        width: 15px;
        height: 15px;
    }
    span{
        cursor: pointer;
        // font-size: 18px;
        // line-height: 18px;
        color: $dark-blue-1;
    }
    .label_holder {
        display: flex;
        align-items: center;
        margin-top: 13px;
        margin-bottom: 45px;
    }
    .button_section {
        @include mobile {
            padding: 0px 0px 12px;
        }
    }
}
