@import "../../../../assets/scss/screen.scss";
@import "../../../../assets/scss/colors.module.scss";


.support_navbar {
    display: flex;
    border-bottom: 1px solid $grey-28;
    .support_all_link {
      width: auto;
      margin: auto 0px;
      line-height: 148%;
      align-items: center;
      color: $grey-1;
      box-sizing: border-box;
    }
    .support_all_link:nth-child(2){
      padding: 0px 50px;
      @include mobile {
        padding: 0px 26px;
      }
    }
    .support_all_link:nth-child(4){
      padding-left: 50px;
      @include mobile {
        padding-left: 26px;
      }
    }
    .support_heading {
      cursor: pointer;
      color: $grey-1;
      padding-bottom: 15px;
        @include mobile {
          width: 117px;
          max-width: max-content;
        }
      }
      .navbar_border_bottom {
        color: $orange-1;
        &:after {
          content: "";
          display: block;
          width: 100%;
          border: 1px solid $orange-1;
          background-color: $orange-1;
          border-radius: 4px;
          font-weight: bold !important;
          font-size: 18px;
          margin-top: 5px;
          line-height: 20.7px;
        }
      }
      @include mobile {
        overflow-y: scroll;
      }
}
