@import "../../../../../assets/scss/screen.scss";
@import "../../../../../assets/scss/colors.module.scss";

.related_container {
    .common_container {
        width: 100%;
    }

    .related_orders_container {
        border: 1px solid $grey-28;
        box-sizing: border-box;
        margin-bottom: 15px;
        box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        width: 100%;
    }

    .related_order_wrapper {
        padding: 13px 35px 28px 35px;
        @include mobile {
            padding: 19px 17px;
        }
    }

    .related_orders_header {
        display: flex;
        background: #F2F6F8;
        border-radius: 20px 20px 0px 0px;
        justify-content: space-between;
        padding: 10px 21px 10px 17px;
    }

    .related_header {
        color: $grey-1;
        @include mobile {
            width: 80%;
        }
    }

    .related_data {
        display: flex;
        justify-content: space-between;
    }

    .related_order_date {
        color: $grey-2;
    }

    .related_order_number {
        color: $grey-1;
        font-size: 16px;
    }

    .open_related_order {
        padding-top: 16px;
        padding-bottom: 5px;
        color: $grey-1;
        font-size: 16px;
        &.inner-flex {
            @include mobile {
                display: flex;
                justify-content: space-between;
            }
        }
    }

    .return_label {
        padding-top: 18px;
        color: $grey-1;
    }

    .related_order_print {
        width: 20px;
        height: 18px;
        cursor: pointer;
        margin-right: 16px;
    }

    .download_qr {
        display: flex;
        align-items: center;
        margin-top: 5px;
    }

    .eye_img {
        width: 16px;
        height: 9px;
        margin-right: 20px;
    }
    .print_label_container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .print_label_text {
            display: flex;
        }
    }

    .return_label_msg {
        color: $red;
        margin-top: 10px;
    }

    .print_wrapper {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: space-between;
    }

    .expire {
        color: $grey-2;
        @include mobile {
            width: 79px;
            text-align: end;
            position: absolute;
            right: 0;
            top: 8px;
        }
    }

    .instrument {
        padding-top: 5px;
        color: $grey-2;
        @include mobile {
            margin-top: 9px;
            font-size: 16px;
            line-height: 152%;
        }
    }

    .print_container {
        padding-top: 10px;
        width: 100%;
        cursor: pointer;
        font-weight: bold;
        color: #2C87EF;
        font-size: 13px;
    }

    .arrow_bottom_icon {
        transform: rotateZ(-180deg);
    }

    .arrow_icon {
        width: 14px;
        height: 16px;
        display: flex;
        cursor: pointer;
        align-items: center;
    }

    .information_icon {
        width: 18px;
        height: 18px;
        margin-right: 14px;
    }
}