
@import "../../assets/scss/screen.scss";
@import "../../assets/scss/colors.module.scss";
@import "../../assets/scss/light.scss";

label.primary-text-area-label {
  display: flex;
  position: relative;
  border-radius: 3px;
  border: 1px solid $grey-6;
  background: $white;
  width:100%;
  &.error {
    display: flex;
    align-items: center;
    border: 1px solid $error-red;
    background: $error-red;
    color: $white;
  }
  &.error_border {
    display: flex;
    border: 1px solid $error-red !important;
    border-right: 1px solid $error-red;
    h1,span {
      color: $error-red !important;
    }
  }
  &.primary-text-area-one-line-style {
    border: unset;
    border-radius: unset;
    margin-bottom: unset;
  }

  .floating_label {
    position: absolute;
    top: 0;
    z-index: 1;
    color: $grey-2;
    margin-top: 24px;
    width: 105%;
    background-color: $white;
    transform-origin: 0px 0px 0px;
    transform: translateY(-1.5rem) scale(0.9);
    padding: 1.25rem 0px 9px 15px;
    transition: transform 0.3s ease-out 0s;
  }
  .un-floating-label {
    position: absolute;
    z-index: 1;
    color: $dim-grey;
    transform-origin: 0px 0px 0px;
    padding: 19px 0 19px 15px;
    transition: transform 0.3s ease-out 0s;
  }

  textarea { padding: 0 }

  textarea.paddingtop1 {
    width: 100%;
    padding-top: 2rem;

  }

  textarea.primary-text-area-field {
    color: $grey-1;
    display: flex;
    align-self: center;
    width: 100%;
    box-shadow: $white 0px 0px 0px 1.25rem inset;
    padding-top: 2.5rem;
    background-color: $white;
    padding-right: 10px;
    font-size: 18px;
    padding-left: 15px;
    outline: 0px;
    border-radius: 3px;
    &.error_border {
      display: flex;
      border: 1px solid $error-red !important;
      border-right: 1px solid $error-red;
    }
    &.support_description {
      padding: unset;
      margin-top: 45px !important;
    }
  }
}