@import "../../../../assets/scss/screen.scss";
@import "../../../../assets/scss/colors.module.scss";

.user_guides_container {
	margin-top: 50px;
	.user_guides_sub_container {
		margin-bottom: 10px;
	  .user_guides_inner_container {
			display: flex;
			.user_guieds_image {
				width: 89px;
				height: 89px;
				margin-right: 30px;
			}
			.user_guides_heading_container {
				padding-top: 11px;
				.user_guides_heading {
					display: flex;
					min-width: fit-content;
					@include mobile {
						flex-wrap: wrap;
					}
					.product_heading {
						margin: 0;
						color: $black1;
						@include mobile {
							min-width: fit-content;
						}
					}
					.product_title {
						margin: 0 0 0 5px;
						color: $black1;
						@include mobile {
							min-width: fit-content;
						}
					}
				}
				.new_page_icon {
					width: 16px;
					height: 16px;
					display: inline;
					margin-right: 15px;
					padding-top: 10px;
					color: $dark-blue-4;
				}
				.user_guides_link {
					color: $dark-blue-4;
				}
				.view_link {
					display: flex;
					align-items: end;
					cursor: pointer;
				}
			}
		}
		.description {
			margin: 35px 0 0 0;
			width: 435px;
			color: $dim-grey;
			.guides_link {
				color: $dark-blue-4;
			}
			@include mobile {
				width: 100%;
			}
		}
	}
}