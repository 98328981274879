@import "../../../../assets/scss/screen.scss";
@import "../../../../assets/scss/colors.module.scss";


.order_list_container {
    width: 100%;
    display: flex;
    .css-26l3qy-menu{
        margin-top: 0;
    }

    .list_wrapper {
        background: #F7F7FD;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 61px 45px 22px 50px;
        border-bottom: 1px solid $grey-28;
        @include tablet {
            padding: 35px 20px;
        }
        @include mobile {
            padding: 15px;
        }
    }

    .all_list {
        border-radius: 20px;
        padding: 61px 50px 20px 45px;
        background-color: $white;
        @include tablet {
            padding: 25px 20px;
        }
        @include mobile {
            padding: 21px 15px;
        }
    }

    .list_header {
        padding-bottom: 22px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $grey-28;
        justify-content: space-between;
    }
    .order_text {
        margin: unset;
    }

    .list_container {
        display: flex;
        margin-top: 39px;
        flex-direction: column;
    }

    .list_items {
        margin-bottom: 27px;
    }

    .order_date {
        color: $grey-1;
        padding-bottom: 3px;
        margin: unset;
    }

    .order_status {
        color: $grey-1;
    }

    .order_price {
        color: $grey-1;
        padding-bottom: 3px;
        margin: unset;
    }

    .order_number {
        color: $grey-2;
        margin: unset;
        display: flex;
        gap: 5px;
        width: 100%;
        &.parent_status {
            display: flex;
            justify-content: flex-end;
        }
        @include mobile {
            padding: 6px 0px;
            overflow-wrap: break-word;
        }
    }
    .parent_status {
        margin: unset;
        padding-bottom: 9px;
        color: $black1;
        @include mobile {
            padding-right: 0.7rem;
        }
    }

    .status_color {
        color: $black1;
        @include mobile {
            padding: 0px;
        }
    }

    .header_wrapper {
        display: flex;
        padding: 27px 35px;
        width: 85%;
        @include tablet {
            flex-direction: column;
        }
        @include mobile {
            width: 100%;
            padding: 15px;
        }
        .date_and_order {
            padding-bottom: 8px;
            @include mobile {
                padding-bottom: 0px;
            }
        }
    }

    .order_list_item {
        position: relative;
        background: #F2F6F8;
        border-radius: 20px 20px 0px 0px;
        border: 1px solid $grey-28;
        border-bottom: none;
        .return_exchange_style {
            padding: 1rem 0rem 1rem 2.3rem;
            background-color: #E8EDEF;
            border-radius: 20px 20px 0px 0px;
            color: $grey-1;
            @include mobile {
                padding: 0.6rem 0rem 0.6rem 1rem;
                font-size: 16px;
            }
        }
    }

    .return_exchange_order {
        display: flex;
        align-items: center;
        font-size: 18px;
        color: $grey-1;
        border-top: 1px solid $grey-28;
        border-bottom: 1px solid $grey-28;
        width: -webkit-fill-available;
        @include tablet {
            flex-direction: column;
        }
        padding: 8px 35px;
        @include mobile {
            font-size: 16px;
            padding: 8px 15px;
        }
        img {
            width: 18px;
            height: 18px;
            margin-right: 15px;
        }
        :first-child {
            display: flex;
            align-items: center;
        }
        :last-child {
            color: $grey-2;
        }
        .retun_product_order_id {
            margin-left: 40px;
            @include tablet {
                margin-left: 35px;
            }

        }
    }

    .view_details {
        color: $grey-1;
        height: 35px;
        font-size: 12px;
        width: 130px;
        padding: 2px;
        @include mobile {
            right: 10px;
        }
    }

    .line_items {
        display: flex;
        padding-right: 70px;
        flex-direction: column;
        width: fit-content;
        @include tablet {
            padding-right: 30px;
        }
    }

    .product_image {
        width: 89px;
        height: 100%;
    }

    .name_quentiry_container {
        display: flex;
        padding-top: 20px;
        padding-left: 25px;
        @include tablet {
            padding-left: 15px;
            width: 100%;
            justify-content: space-between;
            margin-right: 20px;
        }

    }

    .order_status {
        color: $grey-2;
    }

    .quentity {
        color: $grey-2;
    }

    .item_out_of_lenght {
        color: $grey-2;
    }

    .name_quentity {
        color: $grey-1;
        display: flex;
        padding-right: 40px;
        flex-direction: column;
        @include mobile {
            padding-right: 10px;
        }
    }

    .product_details {
        display: flex;
        border: 1px solid $grey-28;
        border-top: none;
        border-radius: 0px 0px 20px 20px;
        padding: 36px 0px 38px 25px;
        @include mobile {
            padding: 36px 0px 38px 15px;  
        }
    }
}

.status_and_button {
    margin-left: auto;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    right: 25px;
}