@import "../../assets//scss/colors.module.scss";
@import "../../assets/scss/screen.scss";

.main_container{
     width: 35%;
     @include mobile{
         margin-top: 25px;
         width: 90%;
     }
     .back_to_login_page {
        cursor: pointer;
        display: flex;
        justify-content: center;
        margin: 40px 0px 40px;
        color: $dark-blue-4;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 148%;
        @include mobile{
          font-size: 16px;
          line-height: 152%;
        }

      }
      .send_code_button{
        margin-bottom: 40px;
      }
}

.send_otp_value {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 148%;
  margin-bottom: 20px;
  padding-left: 3px;
  color: $grey-1
}

.send_otp_option {
  .option_holder{
    label {
        color: $grey-1;
        padding-bottom:15px;     
    }
 }
}

.verify_otp_container {
  margin: 0 auto;
  flex-direction: column;
  background: $white;
  // .login_option_description {
  //   max-width: 483px;
  //   text-align: center;
  //   line-height: 26px;
  // }
  // .login_resend_description {
  //   margin-top: 10px;
  //   line-height: 26px;
  //   text-align: center;
  // }
  .form_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    // .button_container_in_verify_section {
    //   height: 56px;
    //   display: flex;
    //   justify-content: start;
    //   align-items: center;
    //   .login_button {
    //       width: 108px
    //   }
    // }
    // .additional_container {
    //   text-align: center;
    // }
    .back_to_login_tag {
      text-align: center;
      color: $dark-blue-4;
      cursor: pointer;
      margin: 40px 0px;
    }
    .message_container{
      max-width: 330px;
      margin: -12px 0px 12px;
      @include mobile{
        width: calc(100% - 64px);
      }
    }
    // .temp123{
    //   margin-bottom: 3px;
    //   align-self: center;
    //   &.disabled {
    //       opacity: 0.32;
    //   }
    // }
    // .primary-input-label  {
    //     width: 100%;
    //     height: 56px;
    //     margin: ${props => props.otherError ? '7px auto 24px auto' : '7px auto 36px auto'};
    //     span {
    //         font-size: 18px;
    //         line-height: 26px;
    //         color: ${DIM_GREY};
    //         .label {
    //             color: ${ERROR_RED};
    //             padding: 15px 0;
    //         }
    //     }
    //     @media ${mobile} {
    //         width: calc(100% - 84px);
    //     }
    // }    
  }
}