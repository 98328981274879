@import "../../assets/scss/colors.module.scss";
@import "../../assets//scss/screen.scss";

.account_title {
    // font-size: 24px;
    // line-height: 36px;
    // font-weight: 500;
    padding-bottom: 3px;
    color: $grey-1;
    cursor: pointer;
    margin: 0px;
    &.my-account-line {
        border-bottom: 1px solid $grey-6;
    }
    @include mobile {
        padding-bottom: 12px;
    }
}