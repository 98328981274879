@import "../../../assets/scss/colors.module.scss";
@import "../../../assets/scss/screen.scss";

.redux_form_number_format_input {
    height: auto;
    padding: 25px 0 0 0px;
    border: none;
    outline: none;
    color: $grey-1;
    width: 100%;
}