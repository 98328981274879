.small_loader_main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .small_loader_submain {
        height: 20px;
        width: 20px;
        color: rgba(90, 90, 90, 0.2);
        position: relative;
        border: 5px solid;
        border-radius: 50%;
        border-right-color: #5a5a5a;
        animation: rotate 1s linear infinite;
    }
    
    @keyframes rotate {
        0% {
            transform: rotate(0);
        }
    
        100% {
            transform: rotate(360deg);
        }
    }
}