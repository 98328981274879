.button_container {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    .button_V2.primary {
        min-width: unset;
        width: 135px;
        height: 32px;
    }
}