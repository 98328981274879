@import "../../assets/scss/screen.scss";
@import "../../assets/scss/colors.module.scss";

.pop_up_container {
    position: fixed;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    left: 0;
    transition: opacity 500ms;
    background-color: rgba(25, 24, 22, 0.5);
    visibility: visible;
    opacity: 1;
    z-index: 9999;
    align-items: center;
    &.set_height {
        align-items: flex-start;
        overflow-y:auto;
    }
    .cross_section{
        width: 15px;
        position: absolute;
        top: 26px;
        right: 26px;
        cursor: pointer;
        z-index: 999;
    }
}