@import "../../assets/scss/colors.module.scss";
@import "../../assets//scss/screen.scss";

.my_info_container {
    width: 100%;
    border-top: 1px solid $grey-6;
    .my_info_sub_container {
        display: flex;
        background-color: $white;
        width: 100%;
        height: 100%;
        .my_info_child_container {
            width: 100%;
            background-color: $ghost-white;
            .my_info_account_container {
                margin: 75px;
                @include tablet {
                    margin: 75px;
                }
                @include mobile {
                    padding: 24px 14px;
                    border-left: none;
                    margin: 0px;
                }
                .my_info_password_container {
                    background-color: $white;
                    border-radius: 20px;
                    padding: 35px;
                    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
                    padding-bottom: 35px;
                    @include mobile {
                        padding: 35px 15px;
                    }
                    .my_info_account_title {
                        // font-size: 24px;
                        // line-height: 36px;
                        color: $grey-1;
                        padding-bottom: 3px;
                        cursor: pointer;
                        margin: 0px;
                        &.my-account-line {
                            border-bottom: 1px solid $grey-6;
                        }
                        @include mobile {
                            padding-bottom: 12px;
                        }
                    }
                    .my_info_password_section {
                        overflow: hidden;
                        transition: all 0.3s ease;
                    }
                }
            }
        }
    }
}