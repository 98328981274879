@import "../../../../assets/scss/colors.module.scss";
@import "../../../../assets/scss/screen.scss";

$light-ellipse: #f9d7ca;

.pagination-container {
  margin: 20px 0;
  min-width: 100%;
  display: flex;
  justify-content: flex-end;

  .pagination-next-button,
  .pagination-prev-button {
    margin: 0px 14px;
    cursor: pointer;
    @include mobile {
      margin: unset;
    }
    &.disable-cls {
      color: $grey-6;
      cursor: not-allowed;
      opacity: 0.5;
    }
    label {
      margin: 0px 10px;
      color: $orange-1;
      cursor: pointer;
      &.disable-label {
        color: $grey-6;
        cursor: not-allowed;
      }
    }
  }
  .pagination-summary {
    display: flex;
    align-items: center;
    text-align: right;
    margin-right: 45px;
    color: $dim-grey;

    @include tablet {
      width: 100%;
      justify-content: center;
    }

    @include mobile {
      margin-right: 0px;
      font-size: 16px;
    }
  }

  .pagination-numbring-holder {
    // @include mobile {
    //   display: none;
    // }
    .pagination-digit-container {
      min-width: 28px;
      margin: 0 12px;
      padding: 1px;
      font-size: 18px;
      line-height: 29px;
      text-align: center;
      color: $dim-grey;
      cursor: pointer;
      &.active-page {
        border-radius: 50%;
      }
      &:hover {
        border-radius: 50%;
        background: $light-ellipse;
      }
      @include tablet {
        margin: 0 6px;
      }
      // @include tablet-min;
      // @include tablet {
      //   margin-left: 0.5rem;
      //   &:hover {
      //     background: $orange-1;
      //   }
      // }
    }

    .pagination-not-single-digit {
      min-width: 42px;
      height: 28px;
      font-size: 18px;
      padding: 1px;
      margin: 0 5px;
      line-height: 29px;
      text-align: center;
      cursor: pointer;
      border-radius: 14px;
      color: $dim-grey;
      &.active-page {
        border-radius: 14px;
      }
      &:hover {
        border-radius: 14px;
        background: $light-ellipse;
      }

      // @include tablet-min;
      // @include tablet {
      //   margin-left: 0.5rem;
      //   &:hover {
      //     background: $orange-1;
      //   }
      // }
    }
    .active-page {
      color: $white;
      line-height: 28px;
      background: $orange-1;
    }
  }
  // @include tablet {
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  // }
  .d-flex {
    // @include mobile {
    //   width: 100%;
    //   justify-content: space-between;
    // }
  }
}