@import "../../../assets/scss/colors.module.scss";
@import "../../../assets//scss/screen.scss";
.support-form-container {
    background-color: $ghost-white;
    display: flex;
    width: 100%;
    background: $white;
    .support-form-div{
        width: 100%;
        background: $ghost-white;
        .support-form{
            border-radius: 20px;
            padding: 20px 50px 50px 50px;
            background-color: $white;
            margin: 45px; 
            @include tablet {
                padding: 10px 30px;
            } 
            @include mobile {
                margin: 25px;
                padding: 20px 35px;;
            }
            .back_button {
                display: flex;
                width: fit-content;
                align-items: baseline;
                align-items: center;
                margin: 0 0 40px -19px !important;
                color: $dark-blue-4;
                cursor: pointer;
                @include mobile {
                    margin-bottom: 25px;
                }
                .arrow_icon {
                    width: 7px;
                    height: 13px;
                    margin: 0 10px 0 0;
                    @include mobile {
                        width: 10px;
                        height: 16px
                    }
                }
            }
            .support-form-wrapper {
                display: flex;
                justify-content: start;
                align-items: center;
                padding-bottom: 33px;
                border-bottom: 1px solid $grey-6;
                @include mobile {
                    padding-bottom: unset;
                }
                .support-form-heading {
                    margin: 0;
                    width: 100%;
                    color: $grey-1;
                }
            }
            .support-form-form {
                display: block;
                width: 65%;
                @include mobile {
                    margin: 0;
                    width: 100%;
                }
                .support-form-topiclabel {
                    margin: 35px 0 10px;
                    color: $grey-1;
                }
                .support-form-decholder {
                    margin: 50px 0 16px;
                    label.primary-text-area-label textarea.primary-text-area-field{
                      height: 168px;
                      padding-left: 16px;
                      margin-top: 5px;
                    }                                                           
                    label.primary-text-area-label .un-floating-label {
                      @include mobile {
                        padding-left: 15px;
                      }
                    }
                    label.primary-text-area-label .floating_label {
                        padding-left: 16px;
                    }
                }
                .text_area {
                    display: flex;
                    justify-content: end;
                    color: $grey-1;
                }
                .support-form-messagespan{
                    margin: 51px 0 25px;
                    color: $grey-1;
                }
                .support-form-optionholder {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    max-width: 236px;
                    label {
                        padding-left: 29px;
                    }
                    @include mobile {
                        justify-content: unset;
                        max-width: unset;
                        padding-left: 2px;
                    }
                    .radio-button-holder {
                        label.errl:after{
                            background-color: $dark-blue-3;
                        }
                        input.erri:checked ~ label.errl:before {
                            border: 2px solid $dark-blue-3;
                        }
                    
                        label.errl:before {
                            border: 2px solid $grey-3;
                        }
                        input{
                            &:focus ~ {
                                label:before {
                                    box-shadow: none !important;
                                }
                            }
                        }
                        @include mobile {
                            width: 110px;
                        }
                    }
                }
                .support-form-checkcontact {
                    margin-top: 25px;
                    color: $grey-1;
                }
                .support-form-contactinfo {
                    color: $grey-1;
                }
                .support-form-buttonholder {
                    display: flex;
                    justify-content: flex-end;
                    @include mobile {
                        justify-content: center;
                    }
                    .support-form-submitbutton {
                        width: 136px;
                        height: 56px;
                        margin: 52px 0 100px;
                        @include mobile {
                            width: 100%;
                            height: 51px;
                            max-width: 330px;
                            margin: 50px 0 100px;
                        }
                        :disabled {
                            opacity: 0.5;
                        }
                    }
                }
            }

        }
    }
}
