
@import "../../../assets/scss/screen.scss";
@import "../../../assets/scss/colors.module.scss";

.support_wrapper_container {
    width: 100%;
    display: flex;
    background-color: $white;
    min-height: 100vh;
    .heading1,
    .subheading,
    .body1 {
      align-self: center;
    }
    border-top: 1px solid $grey-6;
    .background_img {
      background-size: contain;
      background-repeat: no-repeat;
    }
    #selected.selector_item {
      border-bottom: 2px solid $orange-1;
      color: $orange-1;
      margin-bottom: -2px;
    }
    #selected.guides_section {
      display: block !important;
    }
    .guides_section,
    .all_other_guides {
      display: none !important;
    }
    #selected.all_other_guides {
      display: block !important;
    }
    .all_other_guides {
      padding: 0;
      margin-bottom: 64px;
      .heading5 {
        margin-bottom: 16px;
      }
      .guide_content {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: start;
        margin-bottom: 40px;
        width: 90%;
        padding-left: 50px;
        box-sizing: border-box;
        @include mobile {
          flex-direction: column;
          align-items: center;
        }
      }
      .guide_title {
        margin: 40px 0;
        width: 90%;
        @media only screen and (max-width: $tablet-width) {
            width: 95%;
            display: flex;
            flex-direction: row-reverse;
          }
        .heading4 {
          display: inline-block;
          width: 90%;
          text-align: left;
          padding: 0 20px;
        }
      }
      .guide_title:after {
        content: url('https://assets.eargo.com/corp/guides_page/GuidesIcon.png');
        float: left;
      }
      .bold {
        // font-size: 24px;
        margin-bottom: 16px;
        @include mobile {
          text-align: center;
        }
      }
      .download_link {
        color: $orange-1;
        margin-bottom: 24px;
        &:hover {
          color: $orange-1;
        }
        @include mobile {
          margin-bottom: 18px;
        }
      }
      .asset_section {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 64px;
        @include mobile {
          margin-right: 0px;
        }
  
        .guide_icon {
          width: 120px;
          height: 120px;
          margin-right: 64px;
          margin-bottom: 16px;
          @include mobile {
            margin-right: 0;
            margin-top: 37px;
          }
        }
      }
      .guide_icon {
        margin-right: 0 !important;
      }
      .other_guides {
        width: 100%;
        background: $white;
        color: $black;
        display: flex;
        flex-direction: column;
        align-items: center;
        .text_section {
          justify-content: flex-start;
          color: $black;
          @include mobile {
            align-items: center;
  
            .body1 {
              text-align: center;
            }
          }
        }
      }
      .accesories_guide {
        display: flex;
        width: 100%;
        padding: 0;
        .heading1 {
          @include mobile {
            margin-bottom: 32px;
          }
        }
        .accessories_guide_items {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 90%;
          padding-left: 50px;
          margin-bottom: 24px;
          box-sizing: border-box;
          @media only screen and (max-width: $tablet-width) {
            flex-wrap: wrap;
          }
          @include mobile {
            .heading5 {
              width: 150px;
            }
          }
        }
  
        .accessories_guide_item {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 40px;
          &:nth-child(-n + 3) {
            margin-right: 1.5%;
            @include mobile {
              margin-right: 0;
            }
          }
          .guide_icon {
            width: 120px;
            height: 120px;
          }
          .download_link {
            color: $orange-1;
            margin-top: 5px;
            margin-bottom: 18px;
          }
        }
      }
    }
    .guides_hero {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      color: $grey-1;
      margin-bottom: 43px;
      @include tablet {
        margin-bottom: 40px;
      }
      @include mobile {
        margin-bottom: 32px;
      }
      .heading1 {
        margin: 72px 19.72% 48px;
        @include tablet {
          margin: 64px 18.16% 32px;
        }
        @include mobile {
          margin: 48px 30px 32px;
        }
      }
      .subheading {
        text-align: center;
        margin: 0 25% 72px;
        @include tablet {
          margin: 0 8.46% 88px;
        }
        @include mobile {
          margin: 0 30px 80px;
        }
      }
    }
    .guides_selector {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      .heading1 {
        margin: 72px 0 56px 0;
        @include mobile {
          max-width: 315px;
        }
      }
      .selector_container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 90% !important;
        position: relative;
        border-bottom: 2px solid $grey-3;
        // @include mobile {
        //   font-size: 19px !important;
        // }
      }
      .selector_item {
        width: 24%;
        color: $grey-1;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: $white;
        cursor: pointer;
        @media only screen and (max-width: $tablet-width) {
          width: 218px;
        }  
        @include mobile {
          font-size: 14px !important;
          text-align: center;
        }
        &:nth-child(-n + 3) {
          margin-right: 5%;
          @media only screen and (max-width: $tablet-width) {
            margin-right: 24px;
          }
          @include mobile {
            margin-right: 0;
          }
        }
      }
    }
    .video_section {
      display: flex;
      flex-direction: column;
      align-items: center;
      .heading1 {
        margin: 72px 0 56px 0;
      }
      .btnContainer {
        width: 100%;
        margin-bottom: 30px;
        text-align: center;
      }
      .vidBtn {
        display: inline-block;
        outline: none;
        // font-weight: normal;
        align-items: center;
      }
      .guide-disclaimer {
        margin-bottom: 30px;
      }
      .video_selector {
        margin: 40px 0;
        width: 90%;
        cursor: pointer;
        @media only screen and (max-width: $tablet-width) {
          width: 95%;
          display: flex;
          flex-direction: row-reverse;
        }
        .heading4 {
          display: inline-block;
          width: 90%;
          text-align: left;
          padding: 0 20px;
        }
      }
      #closed.video_container {
        display: none;
      }
      #open.video_selector:after {
        content: url('https://assets.eargo.com/corp/guides_page/GuidesOpenIcon.png');
        float: left;
      }
      #closed.video_selector:after {
        content: url('https://assets.eargo.com/corp/guides_page/GuidesClosedIcon.png');
        float: left;
      }
  
      .video-info-holder {
        width: 100%;
        padding: 0;
        margin-top: 50px;
        @include mobile {
          margin-top: 44px;
        }
        .button_2 {
          display: flex;
          align-self: center;
          justify-content: center;
          height: 48px;
          border: 1px solid $orange-1;
          color: $orange-1;
          padding: 0 25px;
          text-transform: uppercase;
          :hover {
            cursor: pointer;
            color: $white;
            background: $orange-1;
          }
        }
      }
      .video_container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        place-items: center;
        grid-gap: 30px;
        @include mobile {
          display: flex;
          flex-direction: column;
        }
      }
      .video_container::after {
        content: "";
        padding: 0 15%;
        @media screen and (max-width: 1000px) {
          padding: 0 24%;
        }
      }
      .video_item#more:nth-child(n+4) {
        display: none;
      }
      .video_item#more:nth-child(3) {
        @media screen and (max-width: 1350px) {
          display: none;
        }
        @include mobile {
          display: inherit;
        }
      }
      .video_item {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        flex: 1;
        @media screen and (max-width: 1350px) {
          min-width: 32%;
          // &:nth-child(odd) {
          //   margin-right: 5%;
          // }
        }
        @include tablet {
          min-width: 48%;
        }
        @include mobile {
          display: inline-block;
          min-width: 100%;
          &:nth-child(odd) {
            margin-right: 0;
          }
        }
      }
      &.eargo_neo {
        width: 100%;
        .video_container {
          align-self: flex-start;
          width: 100%;
        }
      }
      .play-btn-hover {
        width: auto !important;
        height: 60px;
        display: none;
      }
      .video_thumbnail {
        display: flex;
        width: 100%;
        position: relative;
        cursor: pointer;
        border: 1px solid $dusty-grey;
        &:hover {
          svg {
            background: blue;
            circle {
              stroke: green;
            }
          }
          .play-btn {
            display: none;
          }
          .play-btn-hover {
            display: block !important;
          }
         }
        .thumbnail_img {
          min-width: 200px;
          width: 100%;
          height: 100%;
          @media screen and (max-width: 1000px) {
            min-width: 0;
            max-width: 100%;
          }
        }
        .thumb-playbtn {
          position: absolute;
          right: 20px;
          bottom: 20px;
          @include mobile {
            width: 56px;
            height: 56px;
          }
        }
      }
      .video-title {
        display: flex;
        height: 60px;
        // font-size: 18px;
        border: 1px solid $dusty-grey;
        justify-content: center;
        align-items: center;
        margin-bottom: 57px;
        text-align: center;
        padding: 0 5%;
        // font-size: 14px;
        text-transform: uppercase;
      }
    }
    .download_link {
      text-decoration: none;
      &:hover {
        color: unset;
      }
    }
    .question_search {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: $dark-blue-1;
      color: $white;
      .heading1 {
        margin: 50px 0 20px;
        @include mobile {
          margin: 42px 0 24px 0;
        }
      }
      .subheading {
        margin-bottom: 30px;
        text-align: center;
        a {
          color: $orange-1;
          // font-weight: bold;
        }
      }
      form {
        position: relative;
        box-sizing: border-box;
      }
      input {
        width: 450px;
        height: 48px;
        background-color: $white;
        border: 1px solid $orange-1;
        color: #575757;
        margin-bottom: 64px;
        padding-left: 21px;
        // font-size: 14px;
        ::placeholder {
          color: $orange-1;
        }
        :-ms-input-placeholder {
          color: $orange-1;
        }
        ::-ms-input-placeholder {
          color: $orange-1;
        }
        @include mobile {
          width: 266px;
        }
      }
      input:focus,
      button:focus {
        outline: none;
      }
      .search_logo_container {
        width: 48px;
        height: 48px;
        background: $orange-1;
        position: absolute;
        top: 0;
        right: 0;
          img {
            width: auto;
          }
        }
      }
    .play-btn {
      display: block;
      width: 60px;
      height: 60px;
      }
  
  .popup-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: opacity 500ms;
    visibility: visible;
    opacity: 1;
    z-index: 99;
    background-color: rgba(0,0,0,0.5);
    overflow: scroll;
  }
  .child_container {
    width: 100%;
    background-color: #F7F7FD;
    @include mobile {
      height: fit-content;
    }
    .support_sub_container {
      background-color: white;
      margin: 75px;
      padding: 35px;
      @include tablet {
        padding: 65px 0px 35px 71px;
        margin: 0px;
      }
      @include mobile {
        padding: 25px;
        border-left: none;
        margin: 0px;
      }
    }
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.support_navbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.support_navbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}