@use "./assets/stylesheets/base/reset.scss";
@use "./assets/stylesheets/base/color";
@use "./assets/scss/common.module.scss";
@use "./assets/scss/screen.scss";

// HINT: CSS for SHEER ID JS, Remove in case of sheerId deletion
#sheerid-lightbox-wrap {
  max-height: 90vh;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#sheerid-lightbox-wrap::-webkit-scrollbar {
  display: none;
}

.yotpo-icon-star,
.yotpo-icon-empty-star,
.yotpo-icon-half-star {
  color: color.$orange-1 !important;
}

.eargo_neo_hifi_mobile {
  .yotpo-icon-star,
  .yotpo-icon-empty-star,
  .yotpo-icon-half-star {
    color: color.$dark-blue-1 !important;
  } 
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  background:  #999999 !important;
}