@import "../../../../assets/scss/screen.scss";
@import "../../../../assets/scss/colors.module.scss";

.order_details_main {
    width: 100%;
    display: flex;

    .order_details_container {
        background: #F7F7FD;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 67px 75px 50px 75px; 
        @include tablet {
            padding: 67px 55px 50px 35px;
        } 
        @include mobile {
            padding: 15px;
        }
        .related_orders {
            color: $grey-1;
            margin: unset;
            padding: 37px 0px 17px 0px;
        }
        .details {
            border-radius: 20px;
            padding: 20px 50px 50px 50px;
            background-color: $white;
            .total_price {
                border-top: 1px solid $grey-28;
            }
            .show_total_price {
                color: $grey-1;
            }
            @include mobile {
                padding: 21px 15px;
            }
            .start_return {
                width: 100%;
            }
            .back_button {
                display: flex;
                width: fit-content;
                align-items: baseline;
                align-items: center;
                margin-bottom: 40px;
                color: $dark-blue-4;
                cursor: pointer;
                .arrow_icon {
                    width: 7px;
                    height: 13px;
                    margin-right: 10px;
                    @include mobile {
                        width: 10px;
                        height: 16px
                    }
                }
            }
            .status_detail {
                display: flex;
                align-items: center;
                border-bottom: 1px solid $grey-28;
                padding-bottom: 20px;
                flex-wrap: wrap;
                justify-content: space-between;
                .date_and_order {
                    display: flex;
                    width: 42%;
                    justify-content: space-between;
                    min-width: fit-content;
                }
                .order_number {
                    margin: unset;
                    font-size: 24px;
                }
                .order_date {
                    display: flex;
                    color: $grey-1;
                    margin: unset;
                    @include tablet {
                        width: 100%;
                        justify-content: space-between;
                        font-size: 20px;
                    }
                    .order_number {
                        margin: unset;
                        margin-left: 56px;
                        @include tablet {
                            margin-left: 10px;
                        }
                        @include mobile {
                            font-size: 20px;
                        }
                    }
                }
                .order_status {
                    display: inline-flex;
                    color: $black;
                    @include tablet {
                        display: flex;
                        width: 100%;
                        margin-top: 10px;
                        justify-content: space-between;
                    }
                    .invoice_container {
                        .invoice {
                            display: flex;
                            margin-left: 32px;
                            font-size: 14px;
                            color: $dark-blue-4;
                            align-items: end;
                            cursor: pointer;
                            .invoice_image {
                                margin-right: 9.5px;
                                width: 14px;
                                height: 17px;
                            }
                        }
                        .invoice_error {
                            display: flex;
                            justify-content: flex-end;
                            margin-top: 2px;
                            align-items: center;
                            color: $error-red;
                            font-size: 0.7rem;
                        }
                    }
                }
            }
            .order_details {
                display: flex;
                justify-content: space-between;
                margin-top: 41px;
                @include tablet {
                    display: contents;
                }
                .order_information {
                    width: 40%;
                    @include tablet {
                        width: auto;
                    }
                    .show_in_tablet {
                        display: none;
                        @include tablet {
                            display: block;
                        }
                    }
                }
                .common_container {
                    width: 100%;
                    .show_in_desktop {
                        @include tablet {
                            display: none;
                        }
                    }
                    .products {
                        padding: 37px 32px 0px 32px;
                        border: 1px solid $grey-28;
                        height: fit-content;
                        box-shadow: 0 13px 12px -20px black;
                        @include tablet {
                            width: auto;
                            margin-top: 25px;
                        }
                        @include mobile {
                            padding: 37px 15px 0px 15px;
                        }
                        .product_name {
                            color: $grey-1;
                            font-weight: 600;
                            font-size: 18px;
                            margin-bottom: 3px;
                            @include mobile {
                                font-size: 16px;
                            }
                        }
                    }
                }
                .common_container_second {
                    margin-left: 14px;
                    // width: 30%;
                    @include tablet {
                        width: auto;
                        margin-left: 0px;
                    }
                    @include mobile {
                        margin-left: 0px;
                        width: auto;
                    }
                    .delivery_details {
                        width: auto;
                        padding: 29px 25px;
                        border: 1px solid $grey-28;
                        margin-bottom: 20px;
                        @include tablet {
                            margin-top: 25px;
                            margin-left: 0px;
                        }
                        @include mobile {
                            padding: 25px;
                        }
                        .checkout_details_to {
                            .address {
                                color: $grey-1;
                                padding-bottom: 18px;
                                border-bottom: 1px solid $grey-4;
                                @include mobile {
                                    font-size: 16px;
                                }
                                span {
                                    color: $grey-1;
                                }
                                .address_detail {
                                    padding-top: 6px;
                                    color: $grey-1;
                                }
                            }
                        }
                        .deliverydate_trackorder_conatiner {
                            padding: 19px 0px 22px 0px;
                            border-bottom: 1px solid $grey-28;
                        }
                        .checkout_details_on {
                            margin-top: 20px;
                            padding-bottom: 20px;
                            border-bottom: 1px solid $grey-4;
                            .delivery_on {
                                display: flex;
                                flex-direction: column;
                                margin-top: 20px;
                                font-size: 18px;
                                font-weight: bolder;
                                @include mobile {
                                    font-size: 16px;
                                }
                                .delivery_on_date {
                                    color: grey;
                                    font-weight: 100;
                                }
                            }
                            .track_order {
                                margin-top: 20px;
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                                .track_order_icon {
                                    width: 19px;
                                    display: inline;
                                }
                                .track_order_heading {
                                    font-size: 13px;
                                    margin-left: 10px;
                                    color: $dark-blue-4;
                                }
                            }
                            .tracking_id {
                                font-size: 12px;
                                margin-top: 3px;
                                color: $grey-2;
                            }
                        }
                        .product_price {
                            margin-left: 52px;
                            font-size: 18px;
                            color: $black1;
                        }
                    }
                }
                .delivery_details {
                    width: 24%;
                    padding: 25px 30px;
                    border: 2px solid $grey-4;
                    .checkout_details {
                        .address {
                            padding-bottom: 18px;
                            border-bottom: 1px solid $grey-4;
                            .address_detail {
                                display: block;
                                color: $grey-1;
                            }
                        }
                    }
                }
            }
        }
    }
}