
@import "../../../assets/scss/screen.scss";
@import "../../../assets/scss/colors.module.scss";

.device_video_content {
  .heading1,
  .body1 {
    align-self: center;
  }
  // #selected.selector_item {
  //   box-shadow: $grey-box-shadow;
  // }
  #selected.guides_section {
    display: block !important;
  }
  .eargo_buttonV2{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button_style {
    margin: 0 auto;
    min-width: fit-content;
    padding: 0px 60px;
    color: white;
    font-size: smaller;
  }
  .all_other_guides {
    padding: 0;
    margin-bottom: 64px;
    .guide_content {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: start;
    }
    .guide_title {
      margin: 40px 0;
      width: 90%;
      @media only screen and (max-width: $tablet-width) {
        width: 95%;
      }
      .heading4 {
        display: inline-block;
        width: 90%;
        text-align: left;
        padding: 0 20px;
      }
    }
    .bold {
      font-size: 24px;
      margin-bottom: 16px;
      @include mobile {
        text-align: center;
      }
    }
    .download_link {
      color: $orange-1;
      margin-bottom: 24px;
      @include mobile {
        margin-bottom: 18px;
      }
    }
    .asset_section {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 64px;
      @include mobile {
        margin-right: 0px;
      }
    }
    .other_guides {
      width: 100%;
      background: $white;
      color: $black;
      display: flex;
      flex-direction: column;
      align-items: center;
      .text_section {
        justify-content: flex-start;
        color: $black;
        @include mobile {
          align-items: center;
          .body1 {
            text-align: center;
          }
        }
      }
    }
    .accesories_guide {
      display: flex;
      width: 100%;
      margin-right: 10%;
      margin-left: 10%;
      padding: 0;
      .heading1 {
        @include mobile {
          margin-bottom: 32px;
        }
      }
    }
  }
  .other_guides {
    width: 100%;
    background: $white;
    color: $black;
    display: flex;
    flex-direction: column;
    align-items: center;
    .text_section {
      justify-content: flex-start;
      color: $black;
      @include mobile {
        align-items: center;
        .body1 {
          text-align: center;
        }
      }
    }
  }

  .faq-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $white;
    color: $green-1;
    margin-bottom: 60px;
    .faq_selector {
      margin: 40px 0;
      width: 90%;
      cursor: pointer;
      @media only screen and (max-width: $tablet-width) {
        width: 95%;
        display: flex;
        flex-direction: row-reverse;
      }
      .heading4 {
        display: inline-block;
        width: 90%;
        text-align: left;
        padding: 0 20px;
      }
    }
    .faq_content {
      display: flex;
      flex-direction: column;
      justify-content: start;
      margin-bottom: 40px;
      width: 90%;
      padding-left: 50px;
      box-sizing: border-box;

      a {
        margin-bottom: 32px;
        text-decoration: none;
        &:hover {
          color: unset;
        }
      }
      a:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
    #closed.faq_content {
      display: none;
    }
    #open.faq_selector:after {
      content: url('https://assets.eargo.com/corp/guides_page/GuidesOpenIcon.png');
      float: left;
    }
    #closed.faq_selector:after {
      content: url('https://assets.eargo.com/corp/guides_page/GuidesClosedIcon.png');
      float: left;
    }
  }
  .mobile_app {
    .heading4 {
      text-transform: unset;
    }
  }
  .guide_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $white;
    color: $green-1;
    .heading1 {
      margin: 64px 0 56px;
      @include mobile {
        margin-bottom: 0;
      }
    }

    #closed.guide_content {
      display: none;
    }
    #open.guide_selector:after {
      content: url('https://assets.eargo.com/corp/guides_page/GuidesOpenIcon.png');
      float: left;
    }
    #closed.guide_selector:after {
      content: url('https://assets.eargo.com/corp/guides_page/GuidesClosedIcon.png');
      float: left;
    }

    .guide_selector {
      margin: 40px 0;
      width: 90%;
      cursor: pointer;
      @media only screen and (max-width: $tablet-width) {
        width: 95%;
        display: flex;
        flex-direction: row-reverse;
      }
      .heading4 {
        display: inline-block;
        width: 90%;
        text-align: left;
        padding: 0 20px;
      }
    }

    .guide_content {
      display: flex;
      flex-direction: column;
      justify-content: start;
      margin-bottom: 40px;
      width: 90%;
      padding-left: 50px;
      box-sizing: border-box;
      @include mobile {
        flex-direction: column;
        align-items: center;
        margin-bottom: 42px;
      }
      .guide_icon {
        width: 120px;
        height: 120px;
        margin-right: 64px;
        margin-bottom: 16px;
        @include mobile {
          margin-right: 0;
          margin-top: 37px;
        }
      }
      .text_section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .body1 {
          text-align: left;
        }
        .download_link {
          align-self: flex-end;
          margin-right: 20px;
          color: $orange-1;
          border-bottom: 1px solid $orange-1;
          @media only screen and (max-width: $tablet-width) {
            margin-top: 40px;
          }
          @include mobile {
            align-self: center;
          }
        }
      }
    }
  }
}