@import "../../../assets/scss/screen.scss";
@import "../../../assets/scss/colors.module.scss";

.my_info_side_bar {
    min-height: 100vh;
    padding-left: 35px;
    border-right: 1px solid $grey-6;
    @include mobile {
        display: none;
    }
    .collapse_option {
        display: flex;
        justify-content: end;
        position: relative;
    }
    .collapse_text {
        color: $dim-grey;
        // font-size: 12px;
        padding: 35px 30px 44px 0px;
    }
    .collapse_wrapper{
        width: 40px;
        height: 40px;
        position: absolute;
        right: -20px;
        top: 22px;
        background: $white;
        border-radius: 50%;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        box-shadow: 3px 0px 5px rgba(0, 0, 0, 0.05);
    }
    .hide_collaspe {
        opacity: 0;
    }
    .collapse_arrow {
        border-radius: 50%;
        width: 10px;
        height: 16px;
        transition: all 0.9s ease;
    }
    .reverse_arrow {
        transition: all 0.9s ease;
        transform: rotateZ(-180deg);
    }
    .my_info_sidebar {
      display: flex;
      overflow: hidden;
      align-items: center;
    }
    .collaspe_image {
        width: 22px;
        height: 22px;
        cursor: pointer;
        margin-right: 15px;
    }
    .sidebar_label{
        // font-size: 15px;
        color: $grey-1;
        display: flex;
        overflow: hidden;
        text-transform: uppercase;
        align-items: center;
        margin-bottom: 27px;
    }
    .show_sidebar_label {
        cursor: pointer;
        min-width: max-content;
    }
    .hide_sidebar_label {
        cursor: pointer;
        display: none;
    }
}

.open_collapse {
    transition : all 0.9s ease;
    max-width: 243px;
    width: 100%;
}

.close_collapse {
    transition : all 0.9s ease;
    max-width: 50px;
    width: 100%;
}