@import "../../assets/scss/colors.module.scss";
@import "../../assets//scss/screen.scss";


.dashboard_section_header {
    background-color: $white;
    @include mobile {
        padding: 25px 37px 15px 49px;
        flex-direction: column;
        background-color: $grey-12;
    }
    .dashboard_select_box {
        display: none;
        @include mobile {
            display: flex;
            margin-bottom: 25px;
        }
        .css-pguflv-control {
            height: 56px;
        }
        .dashboard_select_option {
            width: 100%;
            height: 40px;
            color: $grey-1;
            // font-size: 18px;
            // line-height: 26px;
        }
    }
}
.dashboard_section_container {
    width: 100%;
    min-height: 100vh;
    background: $white;
    display: flex;
}