@import "../../../assets/scss/screen.scss";
@import "../../../assets/scss/colors.module.scss";

.edit_address_form_container {
    display: block;
    .redux-primary-input-holder {
        margin-bottom: 10px;
        .primary-input-label.error_border {
            margin: 0px;
        }
    }
    .edit_address_form_one_line {
        display: flex;
        border-radius: 3px;
        div {
            width: 50%;
        }
        @include mobile {
            display: initial;
            div {
                width: 100%;
            }
        }
        div{
            &:last-child {
                margin-left: 10px;
                @include mobile {
                    margin-left: 0px;
                }
            }
        }
    }
    .edit_address_checkbox_holder {
        display: flex;
        padding-top: 17px;
        @include mobile {
            padding-top: 24px;
        }
    }
    .edit_address_error_message {
        color: $error-red;
        text-align: left;
        // font-size: 14px;
        margin-top: 12px;
    }
    .edit_address_buttons {
        display: flex;
        padding-top: 20px;
        @include mobile {
            padding-top: 24px;
        }
        &.isEdit {
            display: inline-flex;
            padding: 32px 0px 41px;
            @include mobile {
                padding-top: 24px;
            }
        }
        // ${props => props.isEdit
        //     ? `
        //     display: inline-flex;
        //     padding: 32px 0 41px 0;
        //     `
        //     : `
        //     display: flex;
        //     padding-top: 20px;`}
        .edit_address_cancel_button {
            // font-size: 18px;
            color: $orange-1;
            cursor: pointer;
            padding: 8px 23px;
            margin: auto;
        }
    }
}