@import '../../../assets//scss/screen.scss';

.box-design-shadow {
    background: #FFFFFF;
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
}

.new-account-box-design {
    width: 300px;
    height: 273px;
    padding: 35px;
    &.full_size {
        width: 100%;
        height: 100%
    }
    &.auto_height {
        height: auto;
    }
}