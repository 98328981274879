@import "../../assets/scss/screen.scss";
@import "../../assets/scss/colors.module.scss";

.primary-select-ipholder2 {
    cursor: pointer;
    display: flex;
    position: relative;
    border-radius: 3px;
    outline: 0px;
    height: 60px;
    margin-bottom: 50px;
    border: 1px solid $grey-6;
    @include mobile {
        height: 60px;
    }
    &.error_border {
        span {
            color: $error-red !important;
        }
    }
    .primary-select-statelabel {
        padding: 18px 0 0 15px !important;
        position: absolute;
        transform-origin: 0px 0px 0px;
        transition: transform 0.3s ease-out 0s;
        color: $grey-2;
        @include mobile {
        padding: 20px 0 0 16px !important;
        }
        .primary-select-statespan {
            top: 18px;
            position: absolute;
            width: 403px;
            color: $grey-1;
            @include mobile {
                width: 300px;
                top: 20px;
            }
        }
    }
}