@import "../../../assets/scss/colors.module.scss";
@import "../../../assets//scss/screen.scss";

.support-request-container{
    .support-request-line {
        border-top: 5px solid $dark-blue-3;
        @include mobile {
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
        }
    }
    .support-req {
        margin: 0;
        color: $black;
        border-bottom: 1px solid $grey-6; 
        padding: 47px 0 9px;
    }
    .support-request-footer {
        padding-top: 30px;
        height: 26px;
        color: $grey-1;
        @include tablet {
            width: 90%;
            height: unset;
        }
    }
    .more-tickets {
        color: $dark-blue-3;
        display: flex;
        justify-content: center;
        margin: 20px 0 0 0;
        cursor: pointer;
        opacity: 1;
        &.no-more-tickets {
            cursor: no-drop;
            opacity: 0.4;
        }
    }

}

