@import "../../../assets/scss/colors.module.scss";
@import "../../../assets//scss/screen.scss";

.thankyou-popup-container {
    .popup-content-svg-holder {
        display: none;
     }
     .thankyou-popup-heading {
        margin-bottom: 17px;
        color: $grey-1;
        @include mobile {
        margin-top: 19px;
        }
     }
     .thankyou-popup-bodytext {
        margin-bottom: 17px;
        color: $dim-grey;
        @include mobile {
            margin-bottom: 27px;
        }
     }
     .thankyou-popup-responsetext {
        margin-bottom: 17px;
        color: $grey-1;
     }
     .thankyou-popup-contactname {
        display: flex;
        align-items: center;
        .thankyou-popup-contacticon {
            width: 25px;
            height: 17px;
            margin-right: 13px;
            &.phone {
                width: 17px;
                height: 21px;
                margin-right: 11px;
            }
        }
    }
    .thankyou-popup-buttonholder {
        display: flex;
        justify-content: center;
        .thankyou-popup-okbutton {
            margin: 52px 0  25px;
            @include mobile {
                width: 100%;
            }
        }
    }
}