@import "../../../assets/scss/colors.module.scss";
@import "../../../assets/scss/screen.scss";

.redux_number_format_input {
    height: auto;
    width: 100%;
    padding: 25px 0 0;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: $grey-1;
    @include mobile {
        padding: 16px 0 0 16px;
    }
}