$mobile-width-min: 380px;
/* ===== == = === 20em (375px) === = == ===== */
$iphone-regular-min: 23.4375em;
/* ===== == = === 30em (480px) === = == ===== */
$mobile-width-max: 30em;
/* ===== == = === 37.5em (600px) === = == ===== */
$tablet-width-min: 37.5em;
$tablet-width-min-1: 700px;
/* ===== == = === 48em (768px) === = == ===== */
$tablet-width: 48em;
$tablet-width-1: 800px;
$tablet-width-2: 900px;
// /* ===== == = === 56.25em (900px) === = == ===== */
// $tablet-width-min-landscape: 56.25em
/* ===== == = === 69.5em (1112px) === = == ===== */
$desktop-width-min: 69.5em;
$desktop-width-1: 1599px;
$desktop-width-2: 1200px;
$desktop-width-3: 1000px;
/* ===== == = === 90em (2040px) === = == ===== */
$desktop-width-max: 100em;

@mixin mobile-min {
  @media (max-width: #{$mobile-width-min}) {
    @content;
  }
}

@mixin iphone-regular-min {
  @media (max-width: #{$iphone-regular-min}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$tablet-width-min}) {
    @content;
  }
}

@mixin ipad-min {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$desktop-width-min}) {
    @content;
  }
}

@mixin tablet-min {
  @media (min-width: #{$tablet-width-min}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width-min}) {
    @content;
  }
}

:export {
  minMobile: $mobile-width-min;
  mobile: $tablet-width-min;
  maxMobile: $mobile-width-max;
  tablet: $desktop-width-min;
  tabletWidth1: $tablet-width-1;
  tabletWidth2: $tablet-width-2;
  tabletWidthMin1: $tablet-width-min-1;
  desktop: $desktop-width-max;
  desktopWidth1: $desktop-width-1;
  desktopWidth2: $desktop-width-2;
  desktopWidth3: $desktop-width-3;
  minTablet: $tablet-width;
}
