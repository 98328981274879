@use './index' as *;
:export {
  // Define Colors
  white: $white;
  black: $black;
  black1: $black1;
  red: $red;
  dimGrey: $dim-grey;
  dimGrey2: $dim-grey2;
  dimGrey3: $dim-grey3;
  dimGrey4: $dim-grey4;
  dimGrey5: $dim-grey5;
  dimGrey6: $dim-grey6;
  dimGrey7: $dim-grey7;
  dimGrey8: $dim-grey8;
  dimGrey9: $dim-grey9;
  dimGrey10: $dim-grey10;
  whiteSmoke: $white-smoke;
  orange1: $orange-1;
  orange2: $orange-2;
  orange3: $orange-3;
  orange4: $orange-4;
  orange5: $orange-5;
  orange-6: $orange-6;
  blue1: $blue-1;
  blue2: $blue-2;
  darkBlue1: $dark-blue-1;
  darkBlue3: $dark-blue-3;
  darkBlue4: $dark-blue-4;
  lightBlue: $light-blue;
  lightBlue1: $light-blue1;
  green1: $green-1;
  green2: $green-2;
  green3: $green-3;
  grey1: $grey-1;
  grey2: $grey-2;
  grey3: $grey-3;
  grey4: $grey-4;
  grey5: $grey-5;
  grey6: $grey-6;
  grey7: $grey-7;
  grey9: $grey-9;
  grey10: $grey-10;
  grey11: $grey-11;
  grey12: $grey-12;
  grey14: $grey-14;
  grey15: $grey-15;
  grey16: $grey-16;
  grey17: $grey-17;
  grey18: $grey-18;
  grey19: $grey-19;
  grey20: $grey-20;
  grey21: $grey-21;
  grey22: $grey-22;
  grey23: $grey-23;
  grey24: $grey-24;
  grey25: $grey-25;
  grey26: $grey-26;
  grey27: $grey-27;
  grey28: $grey-28;
  grey29: $grey-29;
  dustyGrey: $dusty-grey;
  dustyGrey2: $dusty-grey2;
  brightGrey: $bright_grey;
  brightGrey1: $bright_grey1;
  brightGrey2: $bright-grey2;
  errorRed: $error-red;
  aquaSqueeze: $aqua-squeeze;
  ghostWhite: $ghost-white;
  warning: $warning;
  info: $info;

  // Define Screen Sizes
  minMobile: $mobile-width-min;
  mobile: $tablet-width-min;
  maxMobile: $mobile-width-max;
  minTablet: $tablet-width;
  tablet: $desktop-width-min;
  desktop: $desktop-width-max;
  desktopWidth1: $desktop-width-1;
  desktopWidth2: $desktop-width-2;
  desktopWidth3: $desktop-width-3;
  tabletWidthMin1: $tablet-width-min-1;
  tabletWidth1: $tablet-width-1;
  tabletWidth2: $tablet-width-2;

  // Define Fonts
  light: $light;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: subpixel-antialiased;
}

html {
  font-family: 'Inter', sans-serif;
  -ms-overflow-style: scrollbar;
  height: 100%;

  font-size: $fontroot-desktop;

  @include mobile {
    font-size: $fontroot-mobile;
  }

  @include tablet {
    font-size: $fontroot-tablet;
  }
}

body {
  font-size: 16px;
  margin: 0 auto;
  background-color: #f1f2f2;
  height: 100%;
}

img {
  display: block;
  max-width: 100%;
}

a {
  text-decoration: none;
}

input:-webkit-autofill {
  -webkit-background: $white;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

$spaceamounts: (
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  75,
  100
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone
@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

@each $space in $spaceamounts {
  .m-#{$space} {
    margin: #{$space}px !important;
  }
  .p-#{$space} {
    padding: #{$space}px !important;
  }
}

$alignments: (center, left, right); // Leave this variable alone

@each $align in $alignments {
  .text-align-#{$align} {
    text-align: #{$align} !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
input,
select {
}
.p-relative {
  position: relative;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex !important;
}

.d-list-item {
  display: list-item !important;
}

.center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.between-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.around-flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.start-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.w-100 {
  width: 100%;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-18 {
  margin-bottom: 18px;
}

.pb-4 {
  padding-bottom: 4px !important;
}

.roRev {
  flex-direction: row-reverse !important;
}

// .white {
//   color: $white !important;
// }

.grey-7 {
  color: $grey-7 !important;
}

.uppercase {
  text-transform: uppercase !important;
}
/** Font Family Classes Started **/
.light1 {
  font-family: $light;
}

.regular1 {
}

.super {
  font-family: $standard_super;
}

.bold {
  font-family: $bold;
}
/** Font Family Classes Ended **/

.heading1,
.heading2,
.heading3,
.heading4,
.heading5,
.heading6,
.heading7 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
}

.heading1,
.heading2,
.heading4,
.heading5 {
  @extend .super;
}

/** Font Size Classes Started **/
.fontsize-body3 {
  font-size: $fontsize-body3;
}
/** Font Size Classes Ended **/

.body1,
.body2,
.body3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.body1 {
  font-size: $fontsize-body1;
}

.bd1Bld {
  @extend .bold;
  @extend .body1;
}

.body1_light {
  @extend .light1;
  @extend .body1;
}

.body1_regular {
  @extend .regular1;
  @extend .body1;
}

.body2 {
  font-size: $fontsize-body2;
}

.bd2Re {
  @extend .regular1;
  @extend .body2;
}

.bd2Li {
  @extend .light1;
  @extend .body2;
}

.body3 {
  font-size: $fontsize-body3;
}

.body3_light {
  @extend .light1;
  @extend .body3;
}

.heading1 {
  @include desktop {
    font-size: $fontsize-heading1-desktop;
  }

  @include tablet {
    font-size: $fontsize-heading1-tablet;
  }

  @include mobile {
    font-size: $fontsize-heading1-mobile;
  }
  text-transform: uppercase;
}

.heading2 {
  @include desktop {
    font-size: $fontsize-heading2-desktop;
  }

  @include tablet {
    font-size: $fontsize-heading2-tablet;
  }

  @include mobile {
    font-size: $fontsize-heading2-mobile;
  }
  text-transform: uppercase;
}

.heading3 {
  @include desktop {
    font-size: $fontsize-heading2-desktop;
  }

  @include tablet {
    font-size: $fontsize-heading3-tablet;
  }

  @include mobile {
    font-size: $fontsize-heading2-mobile;
  }
}

.heading4 {
  @include desktop {
    font-size: $fontsize-heading3-desktop;
  }

  @include tablet {
    font-size: $fontsize-heading4-tablet;
  }

  @include mobile {
    font-size: $fontsize-heading3-mobile;
  }
}

.heading5 {
  @include desktop {
    font-size: $fontsize-heading4-desktop;
  }

  @include tablet {
    font-size: $fontsize-heading3-tablet;
  }

  @include mobile {
    font-size: $fontsize-heading2-mobile;
  }
}

.heading6 {
  @include desktop {
    font-size: $fontsize-heading4-desktop;
  }

  @include tablet {
    font-size: $fontsize-heading3-tablet;
  }

  @include mobile {
    font-size: $fontsize-heading4-mobile;
  }
}

.heading7 {
  @include desktop {
    font-size: $fontsize-heading5-desktop;
  }

  @include tablet {
    font-size: $fontsize-heading6-tablet;
  }

  @include mobile {
    font-size: $fontsize-heading5-mobile;
  }
}

// Form Style Started
.floating_label {
  @extend .body3_light;
  position: absolute;
  top: 0;
  color: $dusty-grey2;
  -webkit-transform-origin: 0px 0px 0px;
  transform-origin: 0px 0px 0px;
  transform-origin: 0px 0px 0px;
  -webkit-transform: translateY(-1.5rem) scale(0.9);
  transform: translateY(-1.5rem) scale(0.9);
  padding: 2.25rem 0px 0px 0px;
  -webkit-transition: -webkit-transform 0.3s ease-out 0s;
  transition: -webkit-transform 0.3s ease-out 0s;
  transition: transform 0.3s ease-out 0s;
  transition:
    transform 0.3s ease-out 0s,
    -webkit-transform 0.3s ease-out 0s;
}

.label {
  @extend .body1_light;
  position: absolute;
  color: #666666;
  -webkit-transform-origin: 0px 0px 0px;
  transform-origin: 0px 0px 0px;
  padding: 1.25rem 0px;
  -webkit-transition: -webkit-transform 0.3s ease-out 0s;
  transition: -webkit-transform 0.3s ease-out 0s;
  transition: transform 0.3s ease-out 0s;
  transition:
    transform 0.3s ease-out 0s,
    -webkit-transform 0.3s ease-out 0s;
}

.error_border {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  border: 1px solid $error-red !important;
  border-right: 1px solid $error-red;
  margin: -1px;
  span {
    color: $error-red;
  }
}

.errorSpan {
  color: $error-red;
  font-family: $light;
}
// Form Style Ended

.review_label {
  @extend .body1_regular;
  line-height: 28px;
}

.blockquote:before {
  content: '\201c';
}

.copyright {
  font-size: $fontsize-copyright;
}

section.bgImg {
  span.lazy-load-image-background.lazy-load-image-loaded {
    width: 100% !important; // Set invalid property for overwrite
  }
}

.bgImg {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.fade_in {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.product-yotpo-tag {
  min-height: 17px;
}

.brTit {
  word-break: break-word;
  max-width: 80%;
}

label.new-badge {
  font-size: 14.4px;
  margin-right: 5px;
  color: $grey-1;
  font-family: $bold;
  text-transform: uppercase;
}

input.disable-autocomplete::-webkit-credentials-auto-fill-button,
input.disable-autocomplete::-webkit-contacts-auto-fill-button {
  display: none !important;
}

.enScr {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.enScr::-webkit-scrollbar {
  display: none;
}
.my-account-line {
  border-bottom: 2px solid $grey-4;
}

.eargo-capitalize {
  text-transform: capitalize;
}
body.modal-open {
  overflow: hidden !important;
}

#dark-overlay {
  z-index: 1001;
  background: $black;
  opacity: 0.5;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.full_height {
  height: 100%;
}

.close-btn {
  z-index: 9999 !important;
  position: fixed;
  top: calc(50% - 220px);
  left: calc(50% + 290px);
  font-size: 24px;
  color: $white;
  font-weight: bold;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    left: 90%;
    top: 31%;
  }
}

.disabled_class {
  opacity: 0.6;
  pointer-events: none;
  cursor: not-allowed;
}

#testimonial-modal-movie {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 640px;
  max-height: 70vh;
  width: 95vw;
  height: 100%;
  z-index: 1001;
  background-color: $black;
  @media screen and (max-width: 600px) {
    max-height: 40vh;
  }
}

.RoundedCorner {
  border-radius: 20px;
}

.PopUpRoundedCorner {
  border-radius: 40px;
}

.isDisabled {
  opacity: 0.32;
  cursor: not-allowed !important;
  pointer-events: none;
}

.interFontBold {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
}

.interFontRegular {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}

#video_iframe {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
  height: 100%;
  width: 100%;
  margin: auto;
  background-color: black;
}

#dark-overlay-version2 {
  @extend #dark-overlay;
  opacity: 0.8;
}

.close-btn-version2 {
  @extend .close-btn;
  left: unset;
  top: 25px;
  right: 25px;
}
