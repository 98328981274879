@import "../../../assets/scss/colors.module.scss";
@import "../../../assets/scss/screen.scss";

.bread_modal_container {
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1150;
    outline: 0;
    transition: opacity .15s linear;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    @include mobile {
        z-index: 9999;
        height: 100%;
    }
    &.clsd {
        display: none;
    }
    .modDl {
        max-width: 500px;
        position: absolute;
        top: 40px;
        left: 0;
        right: 0;
        margin: 0 auto;
        @include mobile {
            max-width: 375px;
            top: 20px;
        }
        &.hide {
            display: none;
        }
        .modCn {
            position: relative;
            background-color: $white;
            background-clip: padding-box;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 6px;
            outline: 0;
            .modBd {
                position: relative;
                padding: 0;
                .brdHd {
                    display: flex;
                    justify-content: space-between;
                    padding: 16px 24px;
                    align-items: center;
                    border-bottom: 0.5px solid #d9d9d9;
                    img.close {
                        height: 16px;
                        width: 16px;
                    }
                    .close:hover {
                        cursor: pointer;
                    }
                }
                img.logo {
                    height: 24px;
                    width:118px;
                    
                }

                .brdTi {
                    font-size: 20px;
                    padding: 20px 20px 0 20px;
                    margin: 0px;
                    text-align: left;
                    color: $grey-1;
                }
                .brdSt {
                    text-align: left;
                    color: $grey-1;
                    font-size: 14px;
                    margin: 15px 20px 24px 20px;
                    
                }

                .bread_modal_body {
                    font-size: 15px;
                    color: $grey-2;
                    margin: 0 20px 20px 20px;
                    .section {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        padding-bottom: 24px;
                        .icon {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            
                            .body.typographyV2 {
                                color:$grey-1;
                                font-weight: 700;
                                font-size: 14px;
                                text-align: left;
                            }
                            img {
                                color: transparent;
                                display: inline-block;
                                width: 22px;
                                height: 23px;
                            }
                        }

                        .copy {
                            .body.typographyV2 {
                                font-size: 14px;
                                font-weight: 400;
                                color: $grey-29;
                            }
                        }
                    }
                    .hidden_bread_btn {
                        display: none;
                    }
                    .brdSe {
                        display: flex;
                        justify-content: center;
                        margin-bottom:24px;
                    }
                    p.text-color {
                        color: $grey-1;
                        font-size: 14px;
                    }
                    p.caption-color {
                        color: $grey-29;;
                        margin-top: 24px;
                    }
                }
            }
        }
    }
}