@import "../../../assets/scss/screen.scss";
@import "../../../assets/scss/colors.module.scss";

.address_select_box_ip_holder_2 {
    cursor: pointer;
    display: flex;
    position: relative;
    border: 1px solid $grey-6;
    border-radius: 3px;
    outline: 0px;
    height: 75px;
    margin-bottom: 10px;
    &.error {
        align-items: center;
        height: 67px;
        border: 1px solid $error-red;
        background: $error-red;
        color: $white;
    }
    @include mobile {
        height: 60px;
    }
    // &.state_error_border {
    //     font-size: 14px;
    // }
    .address_select_box_label {
        padding-left: 24px !important;
        position: absolute;
        color: $grey-2;
        transform-origin: 0px 0px 0px;
        transition: transform 0.3s ease-out 0s;

        &.floating_label {
            top: 0;
            transform: translateY(-1.5rem) scale(0.9);
            padding: 2.25rem 0px 0px 0px;
        }
        &.label {
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
        }
        @include mobile {
            padding-left: 16px !important;
        }
    }
    .address_select_box_state_span {
        position: absolute;
        top: 35px;
        padding-left: 24px;
        @include mobile {
            padding-left: 16px;
        }
    }
}