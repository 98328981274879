@import "../../assets//scss/colors.module.scss";
@import "../../assets/scss/screen.scss";
.msg_container{
    height: auto;
    &.error{
        color: $error-red;
    }
    &.success{
        color: $green-1;
    }
}