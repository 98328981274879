@import "../../../../assets/scss/screen.scss";
@import "../../../../assets/scss/colors.module.scss";
@import "../../../../assets/scss/typography.scss";

.main_document_container {
  position: relative;
  .box_container {
    background: $white;
    box-sizing: border-box;
    border: 1px solid $grey-6;
    border-radius: 20px;
    margin: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .normal_width {
      padding: 40px;
      width: 275px;
      display : flex;
      flex-direction: column;
      justify-content: center;
      @include mobile{
        width: 325px;
        height: auto;
        padding: 15px 0px 15px;
      }
      @include tablet{
        width: 325px;
        height: auto;
        padding: 15px 0px 15px;
      }
    }
    .full_width{
      padding: 0px 0px 30px;
      width: 866px;
      display : flex;
      flex-direction: column;
      justify-content: center;
      @include mobile{
        width: 325px;
        height: auto;
        padding: 0px 0px 15px;
      }
      @include tablet{
        width: 325px;
        height: auto;
        padding: 0px 0px 15px;
      }

    }
    .required{
      width: 115px;
      height: 35px;
      margin: 0px auto 0px 0px;
      padding: 5px;
      background: #666666;
      border-radius: 20px 0px;
      color : white;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 152%;
    }
    .upload-icon {
      margin: 32px auto;
      width: 33px;
      height: 33px;
      @include mobile {
        display: none;
        margin: 0px;
      }
      @include tablet {
        display: none;
        margin: 0px;
      }
    }
    .success_image{
      width: 100px;
      height: 100px;
      margin: 25px auto;
      @include mobile {
        width: 70px;
        height: 70px;
      }
      @include tablet {
        width: 70px;
        height: 70px;
      }
    }
    .image_name_holder {
      margin : 0px auto 9px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 148%;
      color: $grey-1;
      width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @include mobile {
        font-size: 16px;
        line-height: 152%;
      }
      @include tablet {
        font-size: 16px;
        line-height: 152%;
      }
    }
    .image_size_holder {
      margin : 0px auto;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 176%;
      color: $grey-1;
    }
    .header{
      display: flex;
      align-items: center;
      justify-content: center;  
      height: 45px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 148%;
      text-transform: uppercase;
      color: $grey-1;
      margin-bottom: 16px;
      @include mobile{
        margin-top: 15px;
        font-size: 16px;
        line-height: 152%;
      }
      @include tablet{
        margin-top: 15px;
        font-size: 16px;
        line-height: 152%;
      }
    }
    .sub-header{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 148%;  
      color: $grey-1;
      @include mobile{
        font-size: 16px;
        line-height: 152%;
      }
      @include tablet{
        font-size: 16px;
        line-height: 152%;
      }
    }
    .upload-description{
      margin-bottom : 16px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 148%;
      color: $grey-1;
      @include mobile{
        margin : 0px;
        display: none;
      }
      @include tablet{
        margin : 0px;
        display: none;
      }
    }
    .normal-text{
      margin-bottom: 24px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 176%;
      color: $grey-1;
      @include mobile {
        display: none;
      }
      @include tablet {
        display: none;
      }
    }
    .button{
      margin : 0px auto;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 176%;    
      color: $grey-1;
      .button_V2 {
        .button_text {
          .sentence_case {
            font-size: 14px;
          }
        }
      }
      @include mobile {
        margin : 40px auto 20px;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
      }
      @include tablet {
        margin : 40px auto 20px;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
  .sizeError{
    transform: translate(0%, 40%);
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    left: 20px;
    right: 20px;
  }
  
  .box_blur{
    filter: blur(5px)
  }
}