@import "../../../assets/scss/colors.module.scss";
@import "../../../assets//scss/screen.scss";

.support-request-detail-container {
    padding: 50px 100px 100px;
    background: $white;
    @include mobile {
       padding: 0px;
   }
   .support-detail-wrapper {
        display: flex;
        justify-content: start;
        border-bottom: 1px solid $grey-6; 
        align-items: center;
        .support-detail-prevarrow {
            width: 10px;
            height: 20px;
            padding-left: 10px;
            cursor: pointer;
            @include mobile {
            padding-left: 28px;
            }
        }
        .support-detail-holder {
            width: 100%;
            text-align: center;
            .support-detail-heading {
                color: $grey-1;
                @include mobile {
                padding-top: 14px;
                }
            }
            .support-detail-number {
                margin: 0;
                padding-bottom: 14px;
                color: $grey-1;
            }
        }
   }
   .support-details-holder {
        display: flex;
        padding: 50px 0 54px;
        border-bottom: 5px solid $dark-blue-3; 
        @include mobile {
            flex-direction: column;
            padding: 26px 24px 54px 27px;
        }
        .support-detail-label {
            padding-right: 73px;
            color: $grey-1;
            @include mobile {
                display: flex;
                padding-top: 26px;
                padding-right: 0px;
                justify-content: space-between;
            }
            .support-detail-data {
                padding-top: 15px;
                color: $dim-grey;
                @include mobile {
                    padding: 0;
                }
            }
        }
   }
   .support-help-wrapper {
        @include mobile {
            padding: 0px 25px 50px 26px;
        }
        .support-help-heading {
            padding-top: 51px;
            color: $black;
            @include mobile {
                padding-top: 54px;
            }
        }
        .suppport-subtext {
            word-break: break-word;
            padding-top: 9px;
            font-weight: 100;
            color: $dim-grey;
        }
   }
}