@import "../../../../assets/scss/colors.module.scss";

.dashboard-banner__container {
  padding: 0 15px 0;
  display: flex;
  font-family: 'Inter', sans-serif;

  & > div {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
    padding: 20px 15px 20px 20px;
    margin: 0 0 15px;
    width: 100%;
    border-radius: 4px;
    background-color: $white;
    overflow: hidden;

    & > .dashboard-banner__type-indicator {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 4px;

      &.dashboard-banner__type-indicator--info {
        background-color: $info;
      }

      &.dashboard-banner__type-indicator--warning {
        background-color: $warning;
      }

      &.dashboard-banner__type-indicator--success {
        background-color: $green-1;
      }

      &.dashboard-banner__type-indicator--error {
        background-color: $error-red;
      }
    }

    & > a > .dashboard-banner__button {
      white-space: nowrap;
    }

    & > .dashboard-banner__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      gap: 9px;

      & > .dashboard-banner__icon {
        flex-shrink: 0;
        width: 32px;
        height: 32px;

        & > img {
          width: 100%;
          height: 100%;
        }

        & > svg {
          width: 100%;
          height: 100%;
        }
      }
      & > .dashboard-banner__copy {
        font-size: 16px;
        line-height: 25.6px;
        font-style: normal;
        font-weight: 400;
      }
    }
    .button-reschedule-call {
      color: #0A0A0A;
      font-size: 16px;
      line-height: 25.6px;
      font-style: normal;
      font-weight: 700;
      width: 125px;
    }
  }

  @media screen and (min-width: 600px) {
    & > div {
      & > .dashboard-banner__content {
        flex-direction: row;
        align-items: center;
      }
    }
  }

  @media screen and (min-width: 900px) {
    & > div {
      flex-direction: row;
      align-items: center;

       & > .dashboard-banner__content {
        align-items: center;
      }
    }
  }

  @media screen and (min-width: 1112px) {
    padding: 0;

    & > div {
      margin: 0px 75px 15px;
      width: 100%;
      flex-direction: row;
      align-items: center;
      gap: 0px;

      & > .dashboard-banner__content {
        flex-direction: row;
        align-items: center;
      }
    }
  }
}
