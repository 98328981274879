@import "../../assets/scss/screen.scss";
@import "../../assets/scss/colors.module.scss";

.split_error_container{
    display: flex;
    margin: auto;
    width: 100%;
    margin-bottom: 37px;
    height: auto;
    color: $grey-1;
    .left_error_container {
      width: 4px;
      height: auto;
      background: #F16B6B;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
      border-radius: 4px 0px 0px 4px;
    }
    .right_error_container {
      width: 100%;
      height: auto;
      padding: 20px;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
      background: #FFFFFF;
      border-radius: 4px;
    }
    .inside_right_sub {
      align-items: center;
    }
    .inside_right {
        display: flex;
        .right_img_error {
            width: 22px;
            height: 22px;
        }
        .right_img_error_sub {
          cursor: pointer;
        }
        .right_inside_right {
            margin-left: 15px;
            // .right_inside_right_heading {
            //   font-weight: 600;
            //   font-size: 18px;
            //   line-height: 148%;
            // }
            &.right_inside_right_sub {
              display: flex;
            }
            // .right_inside_right_sub_heading{
            //   font-family: 'Inter';
            //   font-style: normal;
            //   font-weight: 400;
            //   font-size: 18px;
            //   line-height: 27px;
            // }
            .right_inside_right_error {
              color: #666666;
              margin-top: 13px;
            }
        }
    }
}