@import "../../assets/scss/screen.scss";
@import "../../assets/scss/colors.module.scss";

.header_pdp_cart_container{
    display: flex;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    width: 480px;
    z-index: 1010;
    overflow-y: scroll;
    background: $white;
    @include mobile {
        width: 100%;
    }
}