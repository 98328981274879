@import "../../../assets/scss/colors.module.scss";
@import "../../../assets/scss/screen.scss";

.auth_address_container {
    .popup-content-holder {
        margin:40px auto;
        border-radius:40px;
        @include desktop {
            margin:auto;
        }
    }
}