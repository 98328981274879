@import "../../../assets/scss/screen.scss";
@import "../../../assets/scss/colors.module.scss";
@import "../../../assets/scss/typography.scss";

.dashboard_container {
    width: 100%;
    display: flex;
    background-color: $white;
    min-height: 100vh;
    scroll-behavior: smooth;
    .heading1,
    .subheading,
    .body1 {
      align-self: center;
    }
    border-top: 1px solid $grey-6;
    color: #333333;
.child_container {
    width: 100%;
    background-color: #F7F7FD;
    display: flex;
    flex-direction: column;
    @include mobile {
      height: fit-content;
    }
    .dashboard_insurance_heading{
      padding: 15px;
      border-radius: 20px;
      justify-content: space-between;
      margin-left: 75px;
      display: flex;
      width: 82%;
      margin-bottom: 20px;
      background-color: $white;
      @include mobile {
       margin: 18px;
       flex-direction: column;
       margin-bottom: 15px;
      }
      .dashboard_button_container{
        // font-family: 'Inter';
        // font-size: 14px;
        text-transform: none;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .dashboard_arrow_icon{
        margin-left: 10px;
        height: 12px;
        width: 15px;
      }
      .dashboard_insurance_subheading{
        display: flex;
        .dashboard_insurance_icon{
          height: 40px;
          width: 40px;
          @include mobile {
            display: none;
           }
        }
        .dashboard_insurance_title{
          margin: 0px;
          padding-top: 5px;
          padding-left: 15px;
          // font-family: 'Inter';
          // font-style: normal;
          // font-weight: 700;
          // font-size: 24px;
          // line-height: 132%;
          color: $grey-1;
          @include mobile {
            margin-bottom: 18px;
            // font-size: 20px;
           }
        }
      }
      .insurance_button_main{
        padding-top: 5px;
        .insurance_button{
          // height: 32px;
          // width: 138px;
          @include mobile {
             margin-left: 10px;
           }
        }
      }

    }
    .dashboard_sub_container {
      background-color: white;
      position: relative;
      margin: 75px;
      padding: 35px;
      display: flex;
      flex-direction: column;
      margin-top: unset;
      @include desktop {
        width: 50%;
      }
      @include tablet {
        margin: 0px;
      }
      @include mobile {
        border-left: none;
        margin: 0px;
      }
      &.recent_order_container {
        margin-left: -50px;
        
        @include tablet {
          min-height: 340px;
          margin-top: 20px;
          margin-left: unset;
        }
        @include mobile {
          min-height: 340px;
          margin-top: 20px;
          margin-left: unset;
        }
      }
      .dashboard_box_header {
        margin : 40px 0px;
        @include mobile {
          margin: 32px 0px;
        }
      }
    }
  }
  .avatar {
    background: #D8DFEB;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 11px;
    color: black;
    border-radius: 40px;
    text-transform: uppercase;
  }
  .name_container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .contact_heading {
      img {
          width: auto;
          margin-left: 16px;
      }
      color: $orange-1;
      margin: 23px 0 10px 0;
      span {
          display: flex;
      };
  }
  .dark_grey {
      color: $grey-2;
  }
  .links_Container {
      color: $dark-blue-4;
      display: flex;
      flex-direction: row;
      margin-top: 110px;
      .span_middle {
          padding: 0 1rem;
          margin: 0 1rem;
          border-left: 1px solid $grey-6;
          border-right: 1px solid $grey-6;
      }
      .link {
          color: $dark-blue-4;
          cursor: pointer;
      }
  }
  .first_row {
      display: flex;
      flex-direction: row;
      @include tablet {
        flex-direction: column;
        margin: 20px;
      }
      @include mobile {
        flex-direction: column;
        margin: 20px;
      }
  }
  .video_section_container {
    display: flex;
    flex-direction: column;
    margin: 15px 75px;
    border-radius: 40px;
    background: white;
    padding: 35px;
    @include tablet {
      margin: 20px;
    }
    @include mobile {
      margin: 10px;
    }
  }
  .video_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    .heading1 {
      margin: 72px 0 56px 0;
    }
    .btnContainer {
      width: 100%;
      margin-bottom: 30px;
      text-align: center;
    }
    .vidBtn {
      display: inline-block;
      outline: none;
      // font-weight: normal;
      align-items: center;
    }
    .guide-disclaimer {
      margin-bottom: 30px;
    }
    .video_selector {
      margin: 40px 0;
      width: 90%;
      cursor: pointer;
      @media only screen and (max-width: $tablet-width) {
        width: 95%;
        display: flex;
        flex-direction: row-reverse;
      }
      .heading4 {
        display: inline-block;
        width: 90%;
        text-align: left;
        padding: 0 20px;
      }
    }
    #closed.video_container {
      display: none;
    }
    #open.video_selector:after {
      content: url('https://assets.eargo.com/corp/guides_page/GuidesOpenIcon.png');
      float: left;
    }
    #closed.video_selector:after {
      content: url('https://assets.eargo.com/corp/guides_page/GuidesClosedIcon.png');
      float: left;
    }

    .video-info-holder {
      width: 100%;
      padding: 0;
      margin-top: 50px;
      @include mobile {
        margin-top: 44px;
      }
      .button_2 {
        display: flex;
        align-self: center;
        justify-content: center;
        height: 48px;
        border: 1px solid $orange-1;
        color: $orange-1;
        padding: 0 25px;
        text-transform: uppercase;
        :hover {
          cursor: pointer;
          color: $white;
          background: $orange-1;
        }
      }
    }
    .video_container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      place-items: center;
      grid-gap: 30px;
      @include mobile {
        display: flex;
        flex-direction: column;
      }
    }
    .video_container::after {
      content: "";
      padding: 0 15%;
      @media screen and (max-width: 1000px) {
        padding: 0 24%;
      }
    }
    .video_item#more:nth-child(n+4) {
      display: none;
    }
    .video_item#more:nth-child(3) {
      @media screen and (max-width: 1350px) {
        display: none;
      }
      @include mobile {
        display: inherit;
      }
    }
    .video_item {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      flex: 1;
      @media screen and (max-width: 1350px) {
        min-width: 32%;
        // &:nth-child(odd) {
        //   margin-right: 5%;
        // }
      }
      @include tablet {
        min-width: 48%;
      }
      @include mobile {
        display: inline-block;
        min-width: 100%;
        &:nth-child(odd) {
          margin-right: 0;
        }
      }
    }
    &.eargo_neo {
      width: 100%;
      .video_container {
        align-self: flex-start;
        width: 100%;
      }
    }
    .play-btn-hover {
      width: auto !important;
      height: 60px;
      display: none;
    }
    .play-btn {
        display: block;
        width: 60px;
        height: 60px;
        }
    .video_thumbnail {
      display: flex;
      width: 100%;
      position: relative;
      cursor: pointer;
      border: 1px solid $dusty-grey;
      &:hover {
        svg {
          background: blue;
          circle {
            stroke: green;
          }
        }
        .play-btn {
          display: none;
        }
        .play-btn-hover {
          display: block !important;
        }
       }
      .thumbnail_img {
        min-width: 200px;
        width: 100%;
        height: 100%;
        @media screen and (max-width: 1000px) {
          min-width: 0;
          max-width: 100%;
        }
      }
      .thumb-playbtn {
        position: absolute;
        right: 20px;
        bottom: 20px;
        @include mobile {
          width: 56px;
          height: 56px;
        }
      }
    }
    .video-title {
      display: flex;
      height: 60px;
      // font-size: 18px;
      border: 1px solid $dusty-grey;
      justify-content: center;
      align-items: center;
      margin-bottom: 57px;
      text-align: center;
      padding: 0 5%;
      // font-size: 14px;
      text-transform: uppercase;
    }
  }
  .recent_order {
      display: flex;
      flex-direction: column;
      height: 268px;
      overflow: hidden;
      transition: height 1s ease;
  }
  .number_date_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .order_style {
      color: $black1;
      // font-weight: 600;
      // font-size: 18px;
      @include mobile {
        color: $grey-2;
        // font-weight: 400;
        // font-size: 16px;
      }
    }
    .date {
        color: $grey-2;
        // font-weight: 400;
        // font-size: 18px;
        @include mobile {
        color: $black1;
        // font-weight: 600;
        // font-size: 16px;
        }
    }
    @include mobile {
      padding-bottom: 1.5rem;
      border-bottom: 1px solid $grey-6;
      flex-direction: row-reverse;
    }
  }
  .line_item_container {
    display: flex;
    flex-direction: row;
    margin-top: 33px;
    justify-content: space-between;
    background: white;
    min-height: 100px;
    @include mobile {
      flex-direction: row-reverse;
    }
  }
  .order_img {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
  .price {
    color: $grey-3;
    min-width: 140px;
    text-align: end;
    @include mobile {
      margin: 10px 0;
      text-align: initial;
    }
  }
  .name_price_container {
      display: flex;
      flex-direction: column;
      margin-left: 25px;
      min-width:135px;
      &.desktop {
        @include mobile {
          display: none;
        }
      }
      &.mobile {
        display: none;
        @include mobile {
          display: flex;
          margin-left: unset;
          .order_price {
            display: none;
          }
        }
      }
  }
  .right_side {
    display: flex;
    flex-direction: column;
    .quantity_price {
      // font-size: 18px;
      // @include mobile {
      //   font-size: 16px;
      // }
    }
  }
  .full_order_button {
      margin-top: 20px;
      color: $orange-1;
      margin-left: 126px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      @include mobile {
        justify-content: start;
        margin-left: unset;
        margin-top: -27px;
      }
  }
  .past_orders_link {
    color: $dark-blue-4;
    position: absolute;
    bottom: 40px;
    cursor: pointer;
    @include mobile {
      bottom: 20px;
    }
  }
  .left_side {
    display: flex;
    flex-direction: row;
  }
  .vector {
      width: auto;
      margin-left: 10px;
  }
  .welcome_text {
      margin: 40px 0px 40px 75px ;
      @include mobile {
        // margin-left: 1.2rem;
        margin: 32px 0px 32px 1.2rem ;
      }
  }
}
