@import "../../../assets/scss/screen.scss";
@import "../../../assets/scss/colors.module.scss";
@import "../../../assets/scss/typography.scss";

.uploadDocumentContainer {
  width: 100%;
  display: flex;
  // flex-direction: column;
  background-color: $white;
  width: 100%;
  height: 100%;
  .uploadDocumentRightContainer {
    width: 100%;
    background-color: $dim-grey11;
    .upperBox{
      background-color: $white;
      margin : 90px 25px 30px ;
      border-radius: 20px;
      @include mobile {
       margin: 10px; 
      }
      @include tablet {
        margin: 10px; 
      }
    }
    .title{
      padding : 25px 0px 0px 35px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 132%;
      color: $grey-1;
      @include mobile {
        font-size: 20px;
        line-height: 136%;
      }
      @include tablet {
        font-size: 20px;
        line-height: 136%;
      }
    }
    .sub_title {
      padding : 0px 0px 6px 35px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 176%;
      color: $grey-1;
    }
    .divider_line{
      margin : 0px 35px;
      border: 1px solid $grey-6;
      @include mobile {
        margin : 0px 25px;
      }
      @include tablet {
        margin : 0px 25px;
      }
    }
    .card_wrapper{
      margin : 0px auto;
      width : 882px;
      display: flex;
      justify-content: center;
      @include mobile {
        width: auto;
      }
      @include tablet {
        width: auto;
      }
    }
    .cardContainer{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: start;
      align-items: center;
      flex-direction: row;
      margin: 20px 0px;
      width: 100%;
      @include mobile{
        flex-direction: column;
      }
      @include tablet{
        flex-direction: column;
      }
    }
    .row_display {
      margin : 0px 50px;
      display: flex;
      flex-direction: row;
      @include mobile {
        margin : 0px 12px;
        flex-direction: column;
      }
      @include tablet {
        margin : 0px 12px;
        flex-direction: column;
      }
    }
    .lowerBox{
      background-color: $white;
      margin : 30px 25px ;
      border-radius: 20px;
      @include mobile {
       margin: 10px; 
      }
      @include tablet {
        margin: 10px; 
       }
    }
    .documentRowContainer{
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      margin-top: 15px;
      padding-bottom: 20px;
    }
  }
}