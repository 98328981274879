@import "../../../../assets/scss/screen.scss";
@import "../../../../assets/scss/colors.module.scss";
@import "../../../../assets/scss/typography.scss";

.support-main-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    margin: 75px;
    @include tablet {
        flex-direction: column;
        margin: 20px;
    }
    @include mobile {
        margin: 20px;
    }
    .full_size {
        .full_height {
            .all_request {
                // font-size: 15px;
                color: $dark-blue-4;
                cursor: pointer;
            }
        }
    }
}