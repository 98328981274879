@import "../../../assets/scss/screen.scss";
@import "../../../assets/scss/colors.module.scss";

.payment_section_component {
    background-color: $white;
    border-radius: 20px;
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
    padding: 35px;
    margin-bottom: 15px;
    padding-bottom: 0px;
    @include mobile {
        padding: 35px 15px;
    }
    .payment_account_heading_section {
        display: flex;
        border-bottom: 1px solid $grey-6;
        .payment_section_show_section {
            // font-size: 12px;
            color: $dark-blue-4;
            margin-top: 10px;
            // opacity: ${props => !props.isActive ? 1 : 0.5};
            opacity: 1;
            margin-left: 50px;
            @include mobile {
                margin-left: 20px;
            }
            ::after {
                cursor: pointer;
                margin-left: 10px;
                @include mobile {
                    margin-left: 5px;
                }
            }
            &.isActive{
                opacity: 0.5;
            }
            .payment_section_show_label {
                align-items: center;
                display: flex;
                // font-size: 14px;
                // cursor: ${props => !props.isActive ? 'pointer' : 'not-allowed'};
                cursor: pointer;
                &.isActive {
                    cursor: not-allowed;                
                }
            }
        }
    }
    .payment_section_details_container {
        overflow: hidden;
        transition: all 0.3s ease;
    }
    .payment_section_no_info {
        // font-size : 18px;
        padding-top: 26px;
        color: $grey-1; 
        @include mobile {
            padding-bottom: 24px;
        }
    }
    .payment_section_warning_info {
        color: $error-red;
        // font-size: 14px;
        // font-weight: 400;
        margin-bottom: -16px;
        @include mobile {
            margin-bottom:12px;
        }
    }
    .payment_section_add_button {
        padding: 28px 0 56px 0;
        min-width: 200px;
        @include mobile {
            padding: 0px 0 12px 0;
        }
    }
}