@import '../../../assets/scss/colors.module.scss';
@import '../../../assets//scss/screen.scss';

.support_request_title {
  // font-size: 18px;
  // line-height: 26px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  // font-family: ${regular};
  .support-subject-heading {
    color: $dim-grey;
    span {
      color: $black;
    }
    .support_request_status {
      // font-size: 14px;
      // line-height: 22px;
      // padding-top: ${props => props.status ? 26 : 28}px;
      padding-top: 28px;
      margin-bottom: 11px;
      // color: ${props => ({ theme: { color } }) => props.status ? color.ORANGE_1 : color.DIM_GREY};
      color: $dim-grey;
      text-transform: uppercase;
      // font-family: ${bold};
      @include mobile {
        padding-top: 26px;
      }
      &.isActive {
        padding-top: 26px;
        color: $orange-1;
      }
    }
    .support_request_one_line {
      display: flex;
      flex-direction: row;
      margin-top: 9px;
      @include mobile {
        margin-top: 5px;
        flex-direction: column;
      }
      .support_request_created_date {
        padding-right: 20px;

        @include mobile {
          padding-right: 30px;
        }
      }
    }
  }
  .support_request_next_arrow {
    width: 10px;
    height: 33px;
    margin-left: 30px;
    padding-top: 15px;
    cursor: pointer;
    @include mobile {
      padding-top: 40px;
      margin-right: 0;
    }
  }
}

.support_request_holder {
  display: flex;
  padding-bottom: 21px;
  color: $dim-grey;
  @include mobile {
    flex-direction: column;
    padding-bottom: 22px;
  }
  :last-child {
    border-bottom: none;
  }
}

.support_request_dim_line {
  // border-bottom: ${props => ({ theme: { color } }) => props.status ? `1.5px Solid ${color.DIM_GREY}` : `1px Solid ${color.GREY_6}`};
  border-bottom: 1px Solid $grey-6;
  &.isActive {
    border-bottom: 1.5px Solid $dim-grey;
  }
  @include mobile {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
  }
}
