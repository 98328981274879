@import '../../../../assets/scss/screen.scss';

.App_links {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 182px;
    @include mobile {
        justify-content: center;
    }
    .appStore {
        width: 153px;
        height: 56px;
    }
    .playStore {
        width: 173px;
        height: 56px;
        margin-left: 27px;
    }
}