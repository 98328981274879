@import '../../../assets/scss/colors.module.scss';
@import '../../../assets//scss/screen.scss';

.support_notification_container {
  height: 40px;
  width: 100%;
  cursor: pointer;
  background: $orange-1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .support_notification_holder {
    display: flex;

    .support-notification-text {
      color: $white;
      margin-left: -10px;
    }
  }
  .support_notification_icon {
    width: 13px;
    padding-left: 22px;
    padding-right: 22px;
    filter: brightness(0) invert(1);
  }
}
