@import "../../assets/scss/colors.module.scss";
@import "../../assets//scss/screen.scss";

.delete_pop_up_container {
    .popup-content-holder {
        border-radius: 40px;
        width: 100%;
        max-width: 490px;
        max-height: 352px;
        @include mobile {
            width: 80%;
            max-width: 344px;
            max-height: 801px;
        }
        .popup-content-svg-holder {
            right: 24px;
            top: 24px;
            @include tablet {
                width: 24px;
            }
            @include mobile {
                top: 26px;  
                width: 26px;          
            }
        }
    }
    .delete_pop_up_content {
        padding-bottom: 59px;
        margin: 0 auto;
        max-width: 434px;
        @include mobile {
            padding-bottom: 30px;
        }
        .delete_pop_up_heading {
            margin: 0px;
            // font-size: 24px;
            text-align: center;
            color: $grey-1;
            // @media tablet {
            //     line-height: 36px;
            // }
            @include mobile {
                margin-top: 85px;
                // font-size: 18px;
                // line-height: 26px;
            }
        }
        .delete_pop_up_subheading {
            // font-size: 18px;
            text-align: center;
            padding-top: 4px;
            // line-height: 26px;
            color: $grey-1;
            @include mobile {
                padding: 32px 0px 8px 0px;
            }
        }
        .delete_pop_up_button_container {
            padding-top: 34px;
            @include mobile {
                // display: contents;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

            }
            .delete_pop_up_action_button {
                
                // font-size: 18px;
                // line-height: 26px;
                // width: 164px;
                @include mobile {
                    // padding: 0;
                    // width: 100%;
                    // height: 48px;
                    // max-width: 312px;
                    margin-top: 24px;
                }
            }
        }
    }
}