@import "../../../assets/scss/colors.module.scss";
@import "../../../assets//scss/screen.scss";

.support-button {
    margin-bottom: 53px;
    &.disabled {
        background-color: $orange-4;
        cursor: not-allowed;
    }
    @include mobile {
        margin: auto auto 35px auto;
    }
}