@import "../../assets/scss/colors.module.scss";
@import "../../assets/scss/screen.scss";

.add_new_card_heading_section {
    padding-bottom: 22px;
    margin: 0 auto;
    .heading{
        margin:0px;
        color: $grey-1
    }
}
.description_section {
    margin-bottom: 33px;
    .description {
        color: $grey-2;
    }
}