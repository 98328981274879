@import "../../assets/scss/screen.scss";
@import "../../assets/scss/colors.module.scss";

.section_title{
    // font-size: 24px;
    // line-height: 36px;
    margin: 0px auto 20px;
    width: 100%;
    color: $grey-1;
    // margin-bottom : ${props => props.isReview ? '28px' : '22px'};
}