@import "../../../assets/scss/screen.scss";
@import "../../../assets/scss/colors.module.scss";

.payment_section_card_form_container {
    display: block;
    .redux-primary-input-holder {
        margin-bottom: 10px;
        label.primary-input-label {
            box-sizing: border-box;
            &.error_border {
                margin: 0px;
            }
        }
        label.primary-input-label-disabled {
            background: rgb(236, 236, 236);
            input:disabled {
                box-shadow: none;
                background: rgb(236, 236, 236);
            }
        }
    }
    .common-state-zipcode-fields {
        margin-bottom: 10px;
        @include mobile {
            margin-bottom: 0px;
        }
    }
    .payment_section_card_form_element_container {
        card-name-container {
            margin-bottom: 20px;
            ::placeholder {
                color: $grey-2;
                opacity: 1;
            }
        }
        .padding-left-0 {
            padding-left: 0px;
        }
        .padding-left-24 {
            padding-left: 24px;
        }
        .card-containers {
            height: 75px;
            border: $grey-6 1px solid;
            border-radius: 3px;
            margin: 10px 0;
            display: flex;
            position: relative;
            @include mobile {
                margin: 5px 0;
                height: 60px;
            }
            h1.label {
                position: absolute;
                top: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                color: $grey-2;
                transform-origin: 0px 0px 0px;
                transition: transform 0.3s ease-out 0s;
            }
            h1.floating_label {
                position: absolute;
                top: 0;
                color: $grey-2;
                transform-origin: 0px 0px 0px;
                transform: translateY(-1.5rem) scale(0.9);
                padding-top: 2.25rem;
                transition: transform 0.3s ease-out 0s;
            }
            &.primary-input-label {
                h1.label {
                    padding-left: 0px;
                }
                &.error_border {
                    h1.label, h1.floating_label {
                        color: $error-red;
                        // font-size: 14px;
                    }
                }
            }
        }
        .card-inputs {
            width: 100%;
            padding: 35px 0 0 24px;
            @include mobile {
                padding: 30px 16px 24px 16px;
            }
        }
        .cyber-source-card-inputs,
        .cyber-source-card-cvc-input {
            overflow-y: hidden;
            width: 100%;
            padding: 25px 0 0 16px;
            @include mobile {
                padding: 16px 0 0 16px;
                z-index: 9999;
            }
        }
        .flex-container {
            display: flex;
            .card-exp-container  {
                @include mobile {
                    margin-bottom: 12px;
                }
            }
            @include mobile {
                flex-direction: column;
            }
            label {
                width: 50%;
                height: 75px;
                overflow: auto;
                &:last-child {
                    margin-left: 10px;
                    @include mobile {
                        margin-left: 0px;
                    }
                }
                @include mobile {
                    width: 99%;
                    height: 60px;
                }
            }
            &.cbs-mode {
                label {
                    @include mobile {
                        width: auto;
                    }
                }
            }
        }
        .name-input {
            width: 100%;
            padding-left: 24px;
        }
        .cardholder-name {
            h1 {
                padding-left: 16px;
                @include mobile {
                    padding-left: 16px;
                }
            }
            &.error_border {
                h1.label, h1.floating_label {
                    // font-size: 14px;
                    color: $error-red;
                }
            }
        }
        .card-exp-container, .card-cvc-container {
            margin-bottom: 0px;
            border-radius: 0px;
        }
        .card-exp-container {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            @include mobile {
                border-radius: 3px;
            }
        }
        .card-cvc-container {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            @include mobile {
                border-radius: 3px;
            }
        }
    }
    .payment_section_checkbox_holder {
        display: flex;
        padding-top: 22px;
    }
    .payment_section_error_message {
        margin-top: 12px;
        color: $error-red;
        // font-size: 14px;
        // font-weight: 400;
    }
    .payment_section_button_holder {
        display: flex;
        padding-top: 21px;
        &.isEdit {
            display: inline-flex;
            padding: 32px 0 46px 0;
        }
        // ${props => props.isEdit
        //     ? `
        //     display: inline-flex;
        //     padding: 32px 0 46px 0;
        //     `
        //     : `
        //     display: flex;
        //     padding-top: 21px;`}
            
        //     `
        .payment_section_save_button {
            :disabled {
                cursor: not-allowed;
                opacity: 0.6;
            }
        }
        .payment_section_cancel_button {
            // font-size: 18px;
            color: $orange-1;
            cursor: pointer;
            padding: 8px 23px;
            margin: auto;
        }
    }
}