/* PHASE 0: Reset */

html,
body,
header,
nav,
h1,
a,
ul,
li,
strong,
main,
button,
i,
section,
img,
div,
h2,
p,
form,
fieldset,
label,
input,
textarea,
span,
article,
footer,
time,
small {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font: inherit;
  color: inherit;
  text-align: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  background: transparent;
}

ul {
  list-style: none;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

input[type="password"],
input[type="email"],
input[type="text"],
input[type="submit"],
textarea,
button {
  /*
  Get rid of native styling. Read more here:
  http://css-tricks.com/almanac/properties/a/appearance/
  */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

button,
input[type="submit"] {
  cursor: pointer;
}

/* Clearfix */

.group:after {
  content: "";
  display: block;
  clear: both;
}
